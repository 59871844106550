import Backbone from 'lib/backbone.subviews/backbone.subviews';
import DoubleBracket from 'app/DoubleBracket';
import form_elements from 'app/FormElements';
import invokeCallbacks from 'app/NVTagCallbacks';
import FormDefUtils from 'app/FormDefUtils';
import { accounting } from 'lib/accounting';
import ErrorViews from 'app/views/ErrorViews';
import templates from 'generated/compiled_handlebars_templates';
import requestAutocomplete from 'app/requestAutocomplete';
import deviceInfo from 'app/deviceInfo';
import timing from 'app/timing';
import currency from 'app/views/helpers/currency';
import PageAlertView from 'app/views/PageAlert';
import Map from 'app/Map';
import loadResource from 'resources/loadResource';
import vgs from 'app/vgs';
import recaptcha from 'app/recaptcha';
import ActionTagConfig from 'config/actionTagConfig';
import databag_config from 'app/databag_config';
import ApplePay from 'app/applePay';
import uuid from 'lib/uuid/uuid';
import ContributionAmountModel from 'app/models/ContributionAmountModel';
import ContributionRecurrenceModel from 'app/models/ContributionRecurrenceModel';
import aliasHelper from 'app/aliasHelper';
import telemetry from 'app/views/helpers/telemetry';
import FormOptimizationExperiments from 'app/FormOptimizationExperiments';
import matchPro from 'app/matchPro';
import cookieConsentConfig from 'app/CookieConsentConfiguration';
import FastActionUser from 'app/models/FastActionUser';
import eaStripe from 'app/eaStripe';
import PayPalCommercePlatformInstance from 'app/eaPayPalCommercePlatform';

var $ = Backbone.$,
  _ = Backbone._;

function focus(el) {
  el.focus();
  if (deviceInfo.mobile) {
    setTimeout(function () {
      el.focus();
    }, 250);
  }
}

function errorFieldFocusableIfElement(error) {
  return !errorFieldIsFocusable(error) || $(error.field.el).is(':visible');
}

function errorFieldIsFocusable(error) {
  return !!(error && error.field && error.field.el);
}

export default Backbone.View.extend({
  __name__: 'NVFormView',
  className: 'at-form',
  defaults: {},
  template: $.noop,
  initialize: function () {
    _.bindAll(this, 'render', 'context', 'processDefinition', 'onResize', 'subviewsWithName', 'renderFeedback',
      'clearFeedback', 'isFirstForm', 'fill', 'query_string_fill_dict', 'get_fill_dict', 'render_fill_dict',
      'quick', 'clear', 'val', 'setval', 'loadResources', 'vgsStateChange', 'getVgsValues', 'getRecaptchaValues', 'resetRecaptcha', 'updateContactMode');
    Backbone.Courier.add(this);
    Backbone.Subviews.add(this);
    this.touched = false;
    this.options.fillDisabled = false;
    this.options.showNotMe = false;
    this.options.filled = false;
    this.options.quick_complete = false;
    this.options.autoSubmitComplete = false;

    this.listenTo(cookieConsentConfig, 'change:functionalAccepted', this.fill);

    this.getField = _.memoize(this.getFieldNoCache);

    // overall mode the form is operating in
    // can be either 'person' or 'org'
    this.contactMode = 'person';

    this.vgs = {
      form: null,
      state: {}
    };

    // used by the 'recaptcha' helper
    this.captcha = {
      publicKey: null, // from formdef
      bypass: null // from formdef --> single-use bypass token
    };

    var step = { index: 0, view: null };   // Private step object
    this.step = function (key, val) {
      if (!_.isUndefined(key)) {
        if (_.isObject(key)) {         // Set step object:   this.step({ foo: 'bar', hello: 'world' }) => this
          step = key;
          return this;
        } else if (!_.isUndefined(val)) { // Set step property: this.step('foo', 'bar') => this
          step[key] = val;
          return this;
        } else {                       // Get step property: this.step('foo') => 'bar'
          return step[key];
        }
      } else {                           // Get step object:   this.step() => { foo: 'bar', hello: 'world' }
        return step;
      }
    };
    this.fillCounter = 0;

    this.hasRenderedSecondaryStep = false;
    timing.start('Form', this.model.id);
    timing.start('Downloading', this.model.id);
    var fetching = this.model.fetch();
    fetching.then(this.loadResources)
      .then(this.processDefinition);
    fetching.fail(function (err) {
      console.error('fetching error', err);
      telemetry.trackException(err, { ErrorType: 'Fetch Error', FormUrl: this.url });
    });
    _.defer(_.bind(function () {
      var $spinner = $('<div class="ngp-spinner" style="width: 100%; height: 200px;"></div>');
      this.$el.append($spinner);
      this.parent.spin($spinner);
      fetching.always(function () {
        $spinner.remove();
      });
    }, this));

    var smart = false;
    try {
      smart = this.options.form_definition.metadata.hideCompletedFormSections ? true : false;
    } catch (err) { }

    if (this.isFirstForm()) {
      smart = false;
    }
    this.options.smart = smart;
    var self = this;
    this.addDataToForm = _.once(function () { self.$('form').data('view', self); });
  },
  loadResources: function (response) {
    response.resources = response.resources || {};
    response.resources.fill = function resourceFill(str) {
      var args = Array.prototype.slice.call(arguments, 1);
      return DoubleBracket.simpleFill(str, args);
    };

    var languageCode = response.metadata ? response.metadata.languageCode : null;

    response.resources.sort = function sort(arr) {
      return !languageCode ?
        arr.sort() :
        arr.sort(function (a, b) { return a.localeCompare(b, languageCode) });
    };

    return loadResource('PrimaryResources', languageCode).then(function (resourceData) {
      response.resources.PrimaryResources = resourceData;
      return response;
    });
  },
  processDefinition: function (response) {
    timing.end('Downloading', this.model.id);
    timing.start('Processing', this.model.id);

    if (response.experiment
      && response.experiment.experimentName === 'FastAction Autofill Setting') {
      FormOptimizationExperiments.treatFormDef(response, this.parent.options.query_string, this.model);
    }

    var def = this.options.form_definition =
      FormDefUtils.fix(response, this.parent.options.query_string, this.parent.options.fast_action_nologin, this.model);

    // This function will apply a function to the form definition
    // if formdef.experiment is not present in a form definition, nothing will happen
    // Additionally, writes chosen variantId to formdef.experiment
    if (def.experiment && def.experiment.experimentName !== 'FastAction Autofill Setting') {
      FormOptimizationExperiments.treatFormDef(def, this.parent.options.query_string, this.model);
    }

    var formview = window.formview = this;
    if (def.metadata && def.metadata.captcha && def.metadata.captcha.key) {
      // init captcha
      formview.captcha.publicKey = def.metadata.captcha.key;
      formview.captcha.bypass = def.metadata.captcha.secureToken;
      recaptcha.init(); // async, kick off script fetch
    }

    if (def.metadata && def.metadata.matchProPublicKey) {
      matchPro.configure(def.metadata.matchProPublicKey);
      matchPro.init();
    }

    var fieldCount = 0;
    var hiddenFields = [];

    this.formSessionId = uuid.v4();

    // determine initial/hidden mode for the form
    var orgToggle = FormDefUtils.find_field(def.form_elements, 'OrganizationToggle');
    var isOrgMode = orgToggle && (orgToggle.default_value === true || orgToggle.value === true);
    this.updateContactMode(isOrgMode ? 'org' : 'person');

    // currency defaults to USD when none provided
    var currencyField = FormDefUtils.find_field(def.form_elements, 'ProcessingCurrency');
    var initialCurrencyCode = currencyField ? (currencyField.value || currencyField.default_value) : def.metadata.currencyCode;
    var curr = currency.setByCode(initialCurrencyCode);
    accounting.settings.currency.symbol = curr.symbol;

    if (def.fastAction) { this.parent.$el.addClass('fastaction-enabled') }
    if (def.fastActionNologin) {
      this.model.set({
        fastActionNologin: true
      });
    }

    // check metadata for payment types
    var hasCreditCard = (def.metadata && def.metadata.accepted_cards && def.metadata.accepted_cards.length);
    var hasDateView = false;
    this.defaults = _.reduce(FormDefUtils.fields(def.form_elements), function (memo, field) {
      // only credit card is allowed on SSPs for now..
      if (def.type === 'SelfServicePortal' && (field.name === 'Account' || field.name === 'SecurityCode')) {
        hasCreditCard = true;
      }
      if (!_.isUndefined(field.default_value)) {
        memo[field.name] = field.default_value;
      }
      if (field.type && field.type.toLowerCase() === 'date') {
        hasDateView = true;
      }
      return memo;
    }, {});

    // extract alias assignments from form def and register with form view
    this.aliases = aliasHelper.extractAliasMapping(def);

    // Promises that must resolve before we can start rendering
    var preRenderPromises = [];
    if (hasDateView && !deviceInfo.mobile) {
      // load the date picker translations
      // the date picker library is not used for mobile, so skip this step on mobile
      preRenderPromises.push(loadResource('Datepicker', def.metadata && def.metadata.languageCode).then(function (resourceData) {
        def.resources.Datepicker = resourceData;
      }));
    }

    // Load StripeJS
    if (def.metadata.isStripeJsEnabled) {
      eaStripe.initializeStripe(def.metadata.stripePublishableApiKey);
    }

    var vgsLoaded = hasCreditCard
      ? vgs.waitUntilLoaded() : $.Deferred().resolve(false);
    window.nvtag.trigger('sync:formdef', this);
    invokeCallbacks('segue', {
      formviews: this.parent.formviews,
      thank: false,
      calling_tag: this.parent
    });

    // Only allow "placeholder" labels if it's a signup form.
    if (def.type !== 'SignupForm' && this.parent.options.labels !== 'above') {
      var formOptions = formview.model.get('options');
      this.parent.options.labels = this.options.labels = formOptions.labels = 'above';
      formview.model.set('options', formOptions);
    }

    this.multistep = (def.type !== 'AdvocacyForm' && def.metadata.hasOwnProperty('layoutStyle') && def.metadata.layoutStyle === 'multistep');
    this.el.id = 'NV' + def.type + def.formId;
    this.$('form').attr('id', 'NVForm' + def.formId);
    this.userTemplate = this.parent.options.template;

    if (def.steps) {
      this.parent.$el.addClass('faux-multistep-layout');
    }

    // Calculate which payment methods are available from the form definition and client state
    this.paymentMethodConfiguration = this.getPaymentMethodConfiguration(def);


    // The contribution amount model tracks the state that determines the total contribution amount,
    // which includes the base amount and cover cost info
    //
    // The base amount is determined from one of three sources, based on whether amount options are offered,
    // and whether its a ticketed event form.  Only one should be active at a time
    // For ticketed events the whole section (tickets + additional contribution) contribute towards the total.
    // Grab that additional field here (if it exists) just to get the default value
    var selectAmountField = FormDefUtils.find_field(def.form_elements, 'SelectAmount');
    var suggestedAmountField = FormDefUtils.find_field(def.form_elements, 'SuggestedAmount');
    var additionalContributionField = FormDefUtils.find_field(def.form_elements, 'AdditionalContributionValue');
    var activeAmountField = selectAmountField || suggestedAmountField || additionalContributionField || {};
    var coverCostField = FormDefUtils.find_field(def.form_elements, 'CoverCostsAmount') || {};

    var premiumGiftField = FormDefUtils.find_field(def.form_elements, 'PremiumGift') || {};
    var cheapestGiftThreshold = null;

    if (premiumGiftField.gifts && premiumGiftField.gifts.length) {
      var hasNoGiftOption = premiumGiftField.gifts.some(function (el) {
        return el.hasOwnProperty('no_gift') && el.no_gift === true;
      });
      if (hasNoGiftOption) {
        cheapestGiftThreshold = 0;
      } else {
        var sortedGiftThresholds = _.sortBy(premiumGiftField.gifts.map(function (gift) {
          return parseFloat(accounting.toFixed(gift.threshold, 2));
        }));
        cheapestGiftThreshold = sortedGiftThresholds[0];
      }
    }

    this.contributionAmountModel = new ContributionAmountModel({
      cheapestGiftThreshold: cheapestGiftThreshold,
      maxValue: activeAmountField.valueMax || 999999.99,
      baseAmount: activeAmountField.default_value || 0,
      coverCostFormula: coverCostField.formula || null,
      coverCostEnabled: coverCostField.default_value || false
    });

    this.contributionAmountModel.on('change', function () {
      invokeCallbacks('postContributionAmountChanged', {
        view: formview,
        baseAmount: formview.contributionAmountModel.get('baseAmount'),
        coverCostEnabled: formview.contributionAmountModel.get('coverCostEnabled'),
        coverCost: formview.contributionAmountModel.get('coverCost'),
        totalAmount: formview.contributionAmountModel.get('totalAmount'),
      });
    });

    // The contribution recurrence model tracks the state that determines the recurrence options
    var selectedFrequency = FormDefUtils.find_field(def.form_elements, 'SelectedFrequency') || {};
    var isRecurring = FormDefUtils.find_field(def.form_elements, 'IsRecurring') || {};
    this.contributionRecurrenceModel = new ContributionRecurrenceModel({
      allowOneTimeFrequency: selectedFrequency.type === 'radios' && _.any(selectedFrequency.options, { value: '0' }),
      frequency: selectedFrequency.default_value || (selectedFrequency.options && selectedFrequency.options[0] ? selectedFrequency.options[0] : 0),
      isRecurring: (isRecurring.type === 'hidden' && isRecurring.value) || isRecurring.default_value || false,
      frequencyOptions: selectedFrequency.options
    });

    this.contributionRecurrenceModel.on('change', function () {
      invokeCallbacks('postContributionRecurrenceChanged', {
        view: formview,
        isRecurring: formview.contributionRecurrenceModel.get('isRecurring'),
        frequency: formview.contributionRecurrenceModel.get('frequency')
      });
    });

    if (this.paymentMethodConfiguration.shouldFillFromPaymentResponse) {
      var paymentMethodDefinition = {
        name: 'PaymentMethodSection',
        type: 'fieldset',
        children: [{
          name: 'PaymentMethod',
          type: 'payment_method',
          title: def.resources.PrimaryResources.PaymentMethod,
          required: true
        }]
      };

      if (this.multistep) {
        paymentMethodDefinition.step = 0;
        def.form_elements.push(paymentMethodDefinition);
      } else {
        // For single-step forms, we'll insert a definition directly before the contact information section.
        // (This can't happen on the OA side because it can depend on the client-side environment.)
        // We set the last visible fieldset index to the payment method fieldset index to prevent
        // fieldsets below the payment methods from displaying.
        this.lastVisibleFieldsetIndex = _.findIndex(def.form_elements, { 'name': 'ContactInformation' });

        if (this.lastVisibleFieldsetIndex === -1) {
          this.paymentMethodConfiguration.shouldFillFromPaymentResponse = false;
          this.lastVisibleFieldsetIndex = null;
        } else {
          def.form_elements.splice(this.lastVisibleFieldsetIndex, 0, paymentMethodDefinition);
        }
      }
    }

    $(window).on('resize', _.debounce(this.onResize, 250));

    if (this.multistep) {
      $('body').addClass('page-ngp-multistep');
      this.parent.$el.addClass('multistep-layout');
      var opts = _.clone(this.parent.options);
      this.parentOptions = {
        labels: opts.labels,
        template: opts.template,
        inline_error_display: opts.inline_error_display
      };
      this.parent.options.template = 'accelerator';
      this.parent.options.inline_error_display = this.options.inline_error_display = 'true';

      // this.steps is an array of arrays of elements that are going to be displayed at each step
      this.steps = [];
      this.totalSteps = _.reduce(def.form_elements, function (steps, el) {
        if (el.type === 'tab' || el.type === 'fieldset' || _.isNumber(el.step)) {
          fieldCount += _.reduce(el.children, function (count, child) {
            if (/^(hidden|markup|submit)$/.test(child.type)) {
              hiddenFields.push(child.name);
              return count;
            } else {
              return ++count;
            }
          }, 0);
          el.multistep = true;

          if (_.isNumber(el.step)) {
            el.humanIndex = el.step + 1;
            if (el.step < this.steps.length) {
              this.steps[el.step].push(el);
            } else {
              this.steps.push([el]);
            }
          } else {
            el.humanIndex = this.steps.length + 1;
            this.steps.push([el]);
          }

        }
        return steps;
      }, 0, this);

      this.hasHeader = !!_.find(def.form_elements, { name: 'HeaderHtml' });
    } else {
      _.each(def.form_elements, function (field) {
        if (field.children) {
          fieldCount += _.reduce(field.children, function (count, child) {
            if (/^(hidden|markup)$/.test(child.type)) {
              hiddenFields.push(child.name);
              return count;
            } else {
              return ++count;
            }
          }, 0);
        }
      });
    }
    var template = (this.parent.options.template || 'minimal').split('');
    template[0] = template[0].toUpperCase();
    nvtag.track(response.type, 'Form Load', template.join(''), fieldCount, this);

    // Load the correct templates, as defined in the tag attributes
    // Certain views such as FastActionView load templates directly, rather than from backbone options.
    // Those template references will not have these function wrappers
    this.parent.options.templates = this.options.templates = _.mapValues(templates, function (templateCb) {
      return function (context, options) {
        // Attach global context values for usage within hbs views
        context.config = _.extend({}, ActionTagConfig, context.config || {});
        context.resources = _.extend({}, def.resources, context.resources || {});
        return templateCb.call(this, context, options);
      };
    });
    this.template = this.options.templates.form;
    window.nvtag.trigger('template', this.parent.options.template);

    var self = this;
    if (window.nvtag.css && window.nvtag.css.state() === 'pending') {
      self.$el.hide();
      window.nvtag.css.always(function () {
        self.$el.show();
        self.onResize();
      });
    }

    window.addEventListener('beforeunload', function () {
      if (formview.currentField) {
        var filledFields = _(formview.getTouched())
          .omit(hiddenFields)
          .filter(function (touched) { return !_.isNull(touched.val()); });
        nvtag.track(response.type, 'Form Abandoned', formview.currentField.name,
          filledFields.size(), this);
      }
    });

    FastActionUser.on('sync', this.fill);

    $.when.apply(this, preRenderPromises).done(function () {
      vgsLoaded.always(function (vgsIsLoaded) {
        var vgsError;
        if (vgsIsLoaded) {
          try {
            this.vgs.form = vgs.createForm(this.vgsStateChange);
          } catch (vgsErr) {
            this.vgs.form = null;
            vgsError = vgsErr;
          }
        }

        // if configured and vgs fails to load for monetary form, log and bail
        if (vgs.isConfigured() && hasCreditCard && !this.vgs.form) {
          var error = vgsError || new Error('VGS Load Error');
          var metaData = {
            ErrorType: 'VGS Exception',
            FormSessionId: this.formSessionId,
            SecondaryAsk: !!(this.options.last_form_state)
          };
          telemetry.trackException({ exception: error, properties: metaData });

          // show a different error message for secondary ask, which is just a generic "thank you" message
          var errMsg = this.options.last_form_state ?
            this.options.form_definition.resources.PrimaryResources.ProblemLoadingSecondaryForm :
            this.options.form_definition.resources.PrimaryResources.ProblemLoadingForm;
          this.$el.html(errMsg);

          timing.end('Processing', this.model.id);
          return this;
        }

        timing.end('Processing', this.model.id);
        this.startTime = _.now();
        this.render();
        if (def['Submit now, I\'m super cereal']) {
          return this.parent.postBack(undefined, undefined, undefined, 'superCereal');
        }
        if (window.FB && window.FB.XFBML) {
          window.FB.XFBML.parse(this.el);
        }
        if (window.twttr && window.twttr.widgets) {
          var loc = window.location;
          var shareUrl = loc.protocol + '//' + loc.hostname + loc.pathname;
          this.$('a[href="https://twitter.com/share"]').attr('data-url', shareUrl);
          window.twttr.widgets.load(this.el);
        }

        if (this.paymentMethodConfiguration.shouldFillFromPaymentResponse) {
          this.on('paypalAuthorized venmoAuthorized applePayAuthorized stripeApplePayAuthorized googlePayAuthorized', this.handlePaymentAuthorization);
          this.on('postPaymentMethodChanged', this.handlePostPaymentMethodChanged);
        }

      }.bind(this));
    }.bind(this));
  },
  recreateVgsForm: function (current) {
    if (!current || !this.vgs.form || current.formId === this.vgs.form.formId) {
      this.vgs.form = vgs.createForm(this.vgsStateChange);
      this.trigger('recreateVgsElement', this.vgs.form);
    }
  },
  getPaymentMethodConfiguration: function (formDef) {
    if (formDef.type !== 'ContributionForm' && formDef.type !== 'EventForm' && formDef.type !== 'SelfServicePortal') {
      return {};
    }

    var isCcEnabled = formDef.metadata.accepted_cards && formDef.metadata.accepted_cards.length > 0;
    var acceptedCards = _.map(formDef.metadata.accepted_cards, function (card) {
      return card === 'AmericanExpress' ? 'amex' : card.toLowerCase();
    });

    var isPayPalEnabled = !!(formDef.metadata.isPayPalEnabled);
    var isVenmoEnabled = !!(formDef.metadata.isVenmoEnabled);

    var payPalIntegrationType = 'Braintree';
    if (formDef.metadata.payPalIntegrationType && formDef.metadata.payPalIntegrationType === 'PayPalCommercePlatform') {
      // Initialize PayPal Commerce Platform instance
      this.PayPalCommercePlatformInstance = new PayPalCommercePlatformInstance(this.formSessionId, formDef.metadata.payPalClientId, formDef.metadata.payPalMerchantId);
      payPalIntegrationType = 'PayPalCommercePlatform';
    }

    var isEftEnabled = !!(formDef.metadata.isEftEnabled);

    // The {Stripe_Global_Merchant_Identity} string originates in the VAN repository.  Any updates must be done there too
    var isStripeApplePay = formDef.metadata.appleMerchantIdentifier && formDef.metadata.appleMerchantIdentifier === '{Stripe_Global_Merchant_Identity}';

    // If no credit cards are acccepted then we won't be able to process apple pay. Also disable in kiosk mode.
    var isKioskMode = this.options.query_string && this.options.query_string.kiosk;
    var isParagonApplePayEnabled = !!formDef.metadata.appleMerchantIdentifier && isCcEnabled
      && !isKioskMode && !isStripeApplePay && ApplePay.enabledOnBrowser(formDef.metadata.tenantUri, false);

    var isStripeJsEnabled = formDef.metadata.isStripeJsEnabled;

    var isStripeApplePayEnabled = isStripeJsEnabled && isStripeApplePay && isCcEnabled && !isKioskMode && eaStripe.stripeApplePayEnabledOnBrowser();

    // Backwards compatibility code for Stripe Apple Pay if Forms Feature changes are not yet released (Revert these in EVA-7300)
    if (!isStripeJsEnabled && isStripeApplePay) {
      isStripeApplePayEnabled = isCcEnabled && !isKioskMode && ApplePay.enabledOnBrowser(formDef.metadata.tenantUri, true);
    }

    var isGooglePayEnabled = isStripeJsEnabled && formDef.metadata.isGooglePayEnabled && isCcEnabled && !isKioskMode && !!deviceInfo.mightSupportGooglePay;

    // Initialize Stripe Element for Stripe Express Checkout payment methods
    if (isStripeJsEnabled && isGooglePayEnabled) {
      // Initialize with a minimum $0.50 amount, whis will be updated accordingly when a web element is attached (ie. Express Checkout)
      eaStripe.createStripeElement(formDef.metadata.stripeAccountId, 0.50);
    }

    var acceptedPaymentMethods = [];
    if (isPayPalEnabled) {
      acceptedPaymentMethods.push('paypal');
    }

    if (isVenmoEnabled) {
      acceptedPaymentMethods.push('venmo');
    }

    if (isEftEnabled) {
      acceptedPaymentMethods.push('eft');
    }

    if (isParagonApplePayEnabled || isStripeApplePayEnabled) {
      acceptedPaymentMethods.push('applepay');
    }

    if (isGooglePayEnabled) {
      acceptedPaymentMethods.push('googlepay');
    }

    if (!isCcEnabled && !acceptedPaymentMethods.length) {
      // If there are no cards and no other payment methods, assume this is a credit card form
      // This is essential logic for SSP, which has no gateways and therefore, no accepted cards by default
      isCcEnabled = true;
    }

    if (isCcEnabled) {
      // Unshifting because credit card should be first in the priority list
      acceptedPaymentMethods.unshift('creditcard');
    }

    return {
      acceptedCards: acceptedCards,
      isPayPalEnabled: isPayPalEnabled,
      isVenmoEnabled: isVenmoEnabled,
      isCcEnabled: isCcEnabled,
      isEftEnabled: isEftEnabled,
      eftLegalDisclaimer: formDef.metadata.eftLegalDisclaimer,
      isParagonApplePayEnabled: isParagonApplePayEnabled,
      isStripeApplePayEnabled: isStripeApplePayEnabled,
      isGooglePayEnabled: isGooglePayEnabled,
      acceptedPaymentMethods: acceptedPaymentMethods,
      showPaymentMethodButtons: acceptedPaymentMethods.length > 1 || isPayPalEnabled || isVenmoEnabled || isParagonApplePayEnabled || isStripeApplePayEnabled || isGooglePayEnabled,
      shouldFillFromPaymentResponse: this.options.form_definition.type === 'ContributionForm' && (isPayPalEnabled || isVenmoEnabled || isParagonApplePayEnabled || isStripeApplePayEnabled || isGooglePayEnabled),
      isInstantPaymentProcessingEnabled: !!(this.options.form_definition.metadata.isInstantPaymentProcessingEnabled),
      payPalIntegrationType: payPalIntegrationType,
      isStripeJsEnabled: isStripeJsEnabled
    };
  },
  handlePostPaymentMethodChanged: function (newMethod) {
    // Now that a payment method has been selected, all fieldsets should be visible
    // This is essential for auto-submitting, because we check if there are any visible errors
    this.lastVisibleFieldsetIndex = null;
    this.selectedPaymentMethod = newMethod;

    if (!this.multistep) {
      // Display hidden fieldsets if necessary by re-rendering step
      this.renderStep(this.step('index'));
    }

    // Premium gifts give users the option of using a different shipping address than
    // their billing address, which is displayed after these payment methods.
    // We want to prevent auto-submitting to give them a chance to choose the correct address.
    var vals = this.val();
    var isPremimumGiftSelected = parseInt(vals.PremiumGift) > 0;

    if ((newMethod === 'applepay' || newMethod === 'paypal' || newMethod === 'venmo'  || newMethod === 'googlepay')
      && this.paymentMethodConfiguration.isInstantPaymentProcessingEnabled
      && !isPremimumGiftSelected) {
      this.focusOnNextRequiredValueOrSubmit({ submissionType: 'instantProcessing' });
    } else if (this.multistep) {
      this.nextStep();
    } else if (this.subviews.ContactInformation) {
      // Focus on the first tabbable element in the Contact Information fieldset
      this.subviews.ContactInformation.focus();
    }
  },
  handlePaymentAuthorization: function (data) {
    // When a payment authorization event occurs for contribution forms, we wish to fill in any contact info
    if (data && data.mappedContactFields) {
      this.externalAutoFill(data.mappedContactFields);
    }
  },
  getAcceptedTokenPool: function () {
    return this.form_definition().metadata.fastActionTokenPool;
  },
  setFastActionState: function (fastActionUser) {
    // Skip if fastaction isn't enabled, or we're in org mode
    if (this.contactMode === 'org' || !this.model.get('fastAction')) {
      return;
    }

    var paymentInfo = this.subviews && this.subviews.PaymentInformation;
    if (paymentInfo) {
      var tokenPool = this.getAcceptedTokenPool();
      var savedCard = fastActionUser.getCardForPool(tokenPool);
      if (savedCard) {
        paymentInfo.changeReadonly(true, savedCard.ccLastFour, savedCard.ccType, tokenPool);
      } else {
        paymentInfo.changeReadonly(false);
      }
    }
  },
  iFrameAddMultistepToBody: function (className) {
    var iframeList = document.querySelectorAll('iframe.meter-frame');
    if (iframeList && iframeList.length !== 0) {
      _.each(iframeList, function (iframe) {
        iframe.contentDocument.body.classList.add(className);
      });
    }
  },
  updateContactMode: function (newMode) {
    if (!newMode) {
      return;
    }
    newMode = newMode.toLowerCase();
    var oldMode = this.contactMode;
    if (newMode !== 'person' && newMode !== 'org') {
      return;
    }

    if (this.aliases) {
      aliasHelper.syncAliasFields(this.aliases, this);
    }

    var className = 'at-mode-' + newMode;
    var removeClassName = 'at-mode-' + (newMode === 'org' ? 'person' : 'org');
    this.$el.toggleClass(removeClassName, false);
    this.$el.toggleClass(className, true);

    var paymentInfo = this.subviews && this.subviews.PaymentInformation;
    if (paymentInfo && newMode === 'org') {
      // when toggling to org mode, clear out readonly CC
      paymentInfo.changeReadonly(false);
    }


    this.contactMode = newMode;
    if (oldMode !== newMode) {
      this.trigger('contactModeChanged', newMode);
    }
  },
  onResize: function (e) { //jshint ignore:line
    if (this.hasHeader) {
      var canSplit = this.parent.$el.parent().innerWidth() >= 960;
      this.parent.$el[(canSplit ? 'add' : 'remove') + 'Class']('split-layout');
    }

    this.trigger('formResized');
  },
  vgsStateChange: function (newState) {
    this.vgs.state = newState;
    this.trigger('vgsStateChange', newState);
  },
  getFieldNoCache: function (name) {
    var field = null;
    _.each(this.subviews, function (view) {
      if (view.subviews && view.subviews[name]) {
        field = view.subviews[name];
        return false;
      } else if (view.name === name) {
        field = view;
        return false;
      }
    });
    return field;
  },
  subviewsWithName: function (name) {
    var allSubviews = _.toArray(this.subviews);
    _.each(allSubviews, function (subview) {
      if (subview.subviews) {
        allSubviews = allSubviews.concat(_.toArray(subview.subviews));
      }
    }, this);

    return _.filter(allSubviews, function (subview) {
      return subview.options.definition && (subview.options.definition.name === name);
    });
  },
  createSubviewDefaults: function () {
    this.subviewDefaults = {
      parent: this,
      templates: this.options.templates,
      labels: this.options.labels,
      formview: this
    };
    return this.subviewDefaults;
  },
  subviewOptions: function (opts) {
    var defaults = this.subviewDefaults || this.createSubviewDefaults();
    opts = _.isNumber(opts) ? { index: opts } : (opts || {});
    opts = _.extend(opts, defaults);
    if ('index' in opts && _.isNumber(opts.index)) {
      opts.definition = this.options.form_definition.form_elements[opts.index];
      if (opts.definition.name) { opts.name = opts.definition.name; }
    }
    opts.formview = this;
    return opts;
  },
  // NVFormView should not do any iteration across child elements, it should just embed a fieldset. - Bjorn
  subviewCreators: {
    shipping_information_view: function (index) {
      return new form_elements.shipping_information_view(this.subviewOptions(index));
    },
    tribute_gift_view: function (index) {
      return new form_elements.tribute_gift_view(this.subviewOptions(index));
    },
    recipient_information_view: function (index) {
      return new form_elements.recipient_information_view(this.subviewOptions(index));
    },
    page_alert: function () {
      return new PageAlertView({ formview: this });
    },
    error_console: function (index) { //jshint ignore: line
      return new ErrorViews.errors_view({ formview: this });
    },
    hidden_view: function (index) {
      return new form_elements.hidden_view(this.subviewOptions(index));
    },
    fieldset_view: function (index) {
      return new form_elements.fieldset_view(this.subviewOptions(index));
    },
    advocacy_view: function (index) {
      return new form_elements.advocacy_view(this.subviewOptions(index));
    },
    advocacy_tweets_view: function (index) {
      return new form_elements.advocacy_tweets_view(this.subviewOptions(index));
    },
    tab_view: function (index) {
      var tab = new form_elements.fieldset_view(this.subviewOptions(index));
      tab.multistep = true;
      return tab;
    },
    markup_view: function (index) {
      return new form_elements.markup_view(this.subviewOptions(index));
    },
    payment_information_view: function (index) {
      var formDef = this.form_definition();
      return new form_elements.payment_information_view(this.subviewOptions({
        index: index,
        accepted_cards: this.paymentMethodConfiguration.acceptedCards,
        post_response: this.options.post_response,
        previous_payment: this.options.previous_payment,
        isKioskEnabled: this.options.query_string && this.options.query_string.kiosk,
        disableCreditCardAutofill: !!(formDef.metadata.disableCreditCardAutofill)
      }));
    },
    contribution_view: function (index) {
      return new form_elements.contribution_view(this.subviewOptions({
        index: index,
        post_response: this.options.post_response,
        multistep: this.multistep
      }));
    },
    tickets_view: function (index) {
      /* jshint -W117 */
      return new ticketing.tickets_view(this.subviewOptions({
        /* jshint +W117 */
        index: index,
        ticket_metadata: this.form_definition().metadata.tickets
      }));
    },
    ticketing_view: function (index) {
      return new form_elements.ticketing_view(this.subviewOptions({
        index: index,
        ticket_levels: this.form_definition().metadata.tickets,
        showTicketHolders: !!this.form_definition().metadata.guest_names.displayName,
        requireTicketHolders: !!this.form_definition().metadata.guest_names.required
      }));
    },
    notme_view: function (index) { // jshint ignore:line
      /* jshint -W117 */
      return new form_elements.notme_view(this.subviewOptions({ child: child }));
      /* jshint +W117 */
    },
    captcha_view: function (index) {
      return new form_elements.captcha_view(this.subviewOptions(index));
    },
    event_information_view: function (index) {
      return new form_elements.event_information_view(this.subviewOptions(index));
    },
    international_phone_view: function (index) {
      return new form_elements.international_phone_view(this.subviewOptions(index));
    }
  },
  _onSubviewsRendered: function () {
    console.timeStamp('NVTag rendered');
    // All the DOM elements should have been added, time for callbacks
    // postRender here twice just to support legacy uses. Should remove at some point...
    // The NVFormView of the form that has just been rendered.
    invokeCallbacks('postRender', {
      form_definition: this.options.form_definition,
      options: this.options.parent_tag.options,
      thank: false
    });

    var steps = this.$('.at-steps');
    if (steps.length) {
      var fa = this.$('.FastAction');
      if (fa.length && steps.length) {
        steps.insertAfter(fa.first());
      } else {
        steps.prependTo(this.$('form'));
      }
    }

    if (this.multistep) {
      this.$('.at-form-submit').append(this.$('.FooterHtml')).find('.at-submit')
        .css({ 'visibility': 'hidden', 'position': 'absolute' });

      steps.after(formview.subviews.error_console.$el);
      this.renderStep(this.step('index'));
      this.$el.append(this.$('.multistep-footer'));
      _.defer(this.onResize);
    }

    // pull in maps if there are any
    Map.loadMap('at-event-map-container');

    this.val();
    timing.end('Render', this.model.id);
    timing.start('Fill', this.model.id);
    this.fill();
    timing.end('Fill', this.model.id);
    timing.end('Form', this.model.id);
    timing.end('Total');

    // set FormValues event in dataLayer
    this.createFormValuesEvent();

    return this;
  },
  renderFeedback: function () {
    return _(this.subviews).invoke('renderFeedback').flatten().compact().value();
  },
  clearFeedback: function () {
    this.$('.at-step.active').removeClass('invalid');
    _.invoke(this.subviews, 'clearFeedback');
    return this;
  },
  events: {
    'change': 'touch',
    'submit form': 'postBack',
    'focus [name]': 'focused',
    'click .at-step': 'gotoStep',
    'click .prevStep': 'prevStep',
    'click .nextStep': 'nextStep',
    'keypress .prevStep': 'prevStep',
    'keypress .nextStep': 'nextStep',
    'click label[for]': 'focusLocalInput',
    'click': 'hideProfileMenuClick',
    'mousemove': 'hideProfileMenuHover',
  },
  focused: function (e) { this.currentField = e.currentTarget; },
  focusLocalInput: function (e) {
    var forId = $(e.currentTarget).attr('for');
    if (forId) {
      var selector = '[id="' + forId + '"]';
      if ($(selector).length > 1) {
        e.preventDefault();
        this.$('#' + forId).focus();
      }
    }
  },
  touch: function () {
    this.touched = true;
  },
  touched: function () {
    return this.touched;
  },
  // Method to determine whether this is the first form in a chain as it has a set
  // of particular special cases
  isFirstForm: function () {
    var nvtag = this.options.parent_tag;
    return nvtag && nvtag.formviews && nvtag.formviews.current === this;
  },
  form_definition: function () {
    return this.options.form_definition;
  },
  multistep: false,
  totalSteps: 1,
  steps: [],
  getStepIndex: function () { return this.step('index') || 0; },
  gotoStep: function (e) {
    e.preventDefault();
    if (!this.multistep) {
      return;
    }
    var clickedStep = $(e.currentTarget).data('step');
    if (clickedStep > this.step('index')) {
      var valid = true;
      while (clickedStep > this.step('index') && valid) {
        valid = this.nextStep();
      }
    } else if (clickedStep < this.step('index')) {
      this.setStep(clickedStep);
    }
  },

  nextStep: function (e) {
    if (!this.multistep) {
      return false;
    }
    // handle click, space, or enter. e === undefined lets other functions call nextStep() directly
    if (e === undefined ||
      e.type === 'click' ||
      (e.type === 'keypress' && (e.keyCode === 13 || e.keyCode === 32))) {
      if (e && e.type === 'click' && e.shiftKey) {
        var self = this;
        requestAutocomplete(function (form) {
          if (!form.err) {
            self.setval(form.data);
          }
        });
      }
      return this.setStep(this.step('index') + 1);
    }
  },
  prevStep: function (e) {
    if (!this.multistep) {
      return false;
    }
    if (e.type === 'click' ||
      (e.type === 'keypress' && (e.keyCode === 13 || e.keyCode === 32))) {
      return this.setStep(this.step('index') - 1);
    }
  },
  setStepByName: function (name) {
    var stepIndex = 0;
    _.each(this.steps, function (step, index) {
      _.each(step, function (subview) {
        if (subview.name === name) {
          stepIndex = index;
          return false;
        }
      });
    });
    return this.renderStep(stepIndex);
  },
  setStep: function (index) {
    var hasErrors = false;

    if (this.step('view')) {
      var errors = [], current = this.$('.at-steps .active');
      if (index > this.step('index')) {
        // see if any of the views have errors

        _.each(this.step('view'), function (stepView) {
          errors = stepView.renderFeedback();
          if (errors.length) {
            // render subview errors
            this.subviews.error_console.renderFeedback(errors);
            stepView.$('.error :input').first().focus();
            hasErrors = true;
          }
        }, this);

        // no errors, render next step, remove any error styling, and update step title
        if (!hasErrors) {
          current.addClass('valid').removeClass('invalid');
          var contribView = _.findWhere(this.step('view'), { name: 'ContributionInformation' });
          if (contribView) {
            var amount = this.contributionAmountModel.get('totalAmount');
            if (amount > 0) {
              current.find('.step-title').text(currency.format(amount));
            }
          }
          this.renderStep(index);
        } else {
          // there were errors somewhere. make current step red
          current.removeClass('valid').addClass('invalid');
        }
      } else if (index < this.step('index')) {
        hasErrors = _.some(this.step('view'), function (stepView) {
          return (stepView.$('label.error').length || stepView.errors().length);
        });

        if (hasErrors) {
          current.removeClass('valid').addClass('invalid');
        } else {
          current.removeClass('invalid').addClass('valid');
        }

        this.renderStep(index);
      }
    }

    return !hasErrors;
  },
  renderStep: function (index) {
    if (!this.multistep) {
      // Hide or show subviews on single step form
      _.each(this.subviews, function (subview) {
        if (subview.hideOrShow) {
          subview.hideOrShow();
        }
      });

      // Show the form submit button only if a payment method has been selected on payment method forms
      this.$('.at-submit').toggle(!this.paymentMethodConfiguration.shouldFillFromPaymentResponse || !!this.selectedPaymentMethod);

      // Show "your donation will be securely processed" if we're showing the submit button and it's a contribution form, not e.g. ticketed event
      this.$('.secure-processing-single-step-div').toggle(this.form_definition().type === 'ContributionForm'
        && (!this.paymentMethodConfiguration.shouldFillFromPaymentResponse || !!this.selectedPaymentMethod));

    } else if (_.isNumber(index) && index >= 0) {
      
      if (index < this.steps.length) {
        this.step('index', index);
        this.step('view', null);
        if (index > 0) {
          this.hasRenderedSecondaryStep = true;
        }
        _.each(this.steps, function (stepElems, stepIndex) {
          _.each(stepElems, function (stepDef) {
            var stepView = this.subviews[stepDef.name];
            if (this.step('index') !== stepIndex) {
              stepView.$el.addClass('hideStep');
            } else {
              if (this.step('view')) {
                this.step('view').push(stepView);
              } else {
                this.step('view', [stepView]);
              }

              stepView.$el.removeClass('hideStep');

              this.$('.at-steps .active').removeClass('active');
              if (!stepView.tab) {
                stepView.tab = $(this.$('.at-steps .at-step').get(stepIndex));
              }
              stepView.tab.addClass('active');

              var invalid = stepView.$('label.error :input');
              if (invalid.length) {
                this.$('.at-step.active').addClass('invalid');
                focus(invalid.first());
              } else if (this.hasRenderedSecondaryStep) {
                this.$('.at-step.active').removeClass('invalid');

                // Only focus on the first view in the step
                if (this.step('view').length === 1) {
                  focus(stepView);
                }
              }

              if (stepView.name === 'ContributionInformation') {
                this.$('.at-step.active').find('.step-title').text(this.options.form_definition.resources.PrimaryResources.Amount);
              }

              // We're going to set the visibility of navigation elements based on the current step
              var isFirstStep = this.step('index') === 0;
              var isLastStep = this.step('index') === this.steps.length - 1;

              // Show the previous button on all but the first step
              this.$('.prevStep').toggle(!isFirstStep);

              // Show the next button on all but the last step
              this.$('.nextStep').toggle(!isLastStep);

              // The submission button will show up on the last step only
              if (isLastStep) {
                this.$('.submitStep').attr('style', 'display: block; visibility: visible; position: static');
              } else {
                this.$('.submitStep').hide();
              }

              $('.secure-processing-div').toggle(isLastStep && this.form_definition().type === 'ContributionForm');

              // Show footer on last step unless it only contains HTML whitespace
              var showFooter = isLastStep && !!$('.FooterHtml').html().replace(/(&nbsp;|<\/?p>|\n\s*)/gi, '');
              this.$('.FooterHtml').toggle(showFooter);

              // Show the navigation buttons unless the payment methods are visible and we're on the first step
              this.$('.step-prevNext').toggle(!this.paymentMethodConfiguration.shouldFillFromPaymentResponse || !isFirstStep);
            }
          }, this);
        }, this);

        this.trigger('renderStep');
        // Trigger an event to force the signature pad canvas to resize on multistep forms
        if (typeof(Event) === 'function') {
          // Modern browsers
          window.dispatchEvent(new Event('signature-pad-multistep-rebind'));
        } else {
          // IE11 and older browsers
          var evt = new CustomEvent('signature-pad-multistep-rebind');
          window.dispatchEvent(evt);
        }
      } else {
        this.$('form').submit();
      }
    }

    // Once the step is rendered, we might want to move the actionable content into view on mobile
    // This will happen either if the data-mobile-autofocus flag is set, or if the supporter has already switched tabs
    if (deviceInfo.mobile) {
      var scrollPoint;
      if (this.hasRenderedSecondaryStep || (this.options.mobileAutofocus && this.multistep && !this.options.form_definition.fastAction)) {
        scrollPoint = '.at-steps';
      } else if (this.options.mobileAutofocus) {
        scrollPoint = this.options.form_definition.fastAction && this.$('.FastAction').length > 0 ? '.FastAction' : '.at-fieldset';
      }
      if (scrollPoint) {
        this.$(scrollPoint).get(0).scrollIntoView();
      }
    }
    return this;
  },
  /**
   * Hides the profile menu
   */
  hideProfileMenu: function () {

    $('#profile-menu').hide();
    $('.profile-link').removeClass('menu-open');
  },
  /**
   * If not hovering the profile link or profile menu then collapse the profile menu
   */
  hideProfileMenuHover: function () {

    if ($('#profile-menu').length === 1) { //profile menu exists
      var profileLinkHover = $('.profile-link.toggle-menu').is(':hover');
      var profileMenuHover = $('#profile-menu').is(':hover');

      if (!profileLinkHover && !profileMenuHover) {
        this.hideProfileMenu();
      }
    }
  },
  /**
   * If the clicked element is not the profile menu element, profile link
   * element, or not any elements located in the same position as the
   * aforementioned elements then hide the profile menu
   *
   * @param [jQuery.Event] Generated jQuery event caused by a click event
   */
  hideProfileMenuClick: function (event) {
    var clickedOnProfileMenu = ($(event.target).closest('#profile-menu').length === 0);
    var clickedOnProfileLink = ($(event.target).closest('.profile-link').length === 0);

    if (!clickedOnProfileMenu && !clickedOnProfileLink) {
      this.hideProfileMenu();
    }
  },
  context: function () {
    var def = this.options.form_definition,
      cols = def.metadata.columns,
      context = { form_elements: [] },
      _fields = _(FormDefUtils.fields(def.form_elements)),
      headerHtml = _fields.find({ name: 'HeaderHtml' }),
      meterHtml = _fields.find({ name: 'MeterHtml' }),
      footerHtml = _fields.find({ name: 'FooterHtml' }),
      submitForm = _fields.find({ name: 'submitForm' });

    // Set all the items to be used in the template
    context.resources = def.resources || {};
    context.form_id = this.model.get('id');
    context.columns = cols;
    context.classes = 'clearfix';
    context.isOberonAndKeepHeaderFooter = this.userTemplate === 'oberon' && !window.exileHeaderAndFooter;
    context.bannerImagePath = def.bannerImagePath || null;
    context.returnToWebsiteUrl = def.returnToWebsiteUrl || null;
    context.returnToWebsiteName = def.returnToWebsiteName || def.returnToWebsiteUrl || null;
    context.paidForByDisclaimerText = def.paidForByDisclaimerText || null;
    context.header_html = headerHtml ? DoubleBracket.translate(headerHtml.markup) : null;
    context.meter_html = meterHtml ? DoubleBracket.translate(meterHtml.markup) : null;
    context.footer_html = footerHtml ? DoubleBracket.translate(footerHtml.markup) : null;
    context.regulationsGovUrl = def.documentId ? 'https://www.regulations.gov/document/' + def.documentId : null;
    context.regulationsGovUrlLabel = 'View this document on Regulations gov';
    context.regulationsGovDocId = def.documentId || null;
    context.submit_label = submitForm ? submitForm.value : (def.submit_label || 'Submit');
    context.poweredby = window.footerHTML ||
      /* jshint -W101 */ /* jshint -W109 */
      "Powered by <a href='http://www.ngpvan.com/?utm_source=act.myngp.com&utm_medium=poweredby&utm_campaign=ngpnext'>NGP VAN</a>";
    /* jshint +W101 */ /* jshint +W109 */
    context.title = this.model.get('title');
    context.hideTitle = this.model.get('hideTitle');

    if (def.steps) {
      context.steps = _.map(def.steps, function (step, i) {
        var meta = {
          title: step,
          static: true,
          classes: ''
        };
        if (def.currentStep === i) {
          meta.classes = 'active';
          if (def.currentStepSuperSaiyan) {
            meta.classes += ' warn';
          }
        } else if (def.currentStep > i) {
          meta.classes = 'valid';
        }
        return meta;
      });
      context.staticSteps = true;
      context.multistep = 'at-multistep';
      context.stepWidth = (100 / (def.steps.length)) - 0.1 + '%';
      context.stepHover = 'default';
    }

    if (this.multistep) {
      context.multistep = this.multistep ? 'at-multistep' : '';
      context.steps = this.steps;
      context.stepWidth = (100 / (this.steps.length)) - 0.1 + '%';
      context.liWidth = this.steps.length < 4 ? '150px' : '100px';
      context.hrWidth = (100 * (this.steps.length - 1)) + '%';
      context.stepHover = 'inherit';
    }

    _.each(def.form_elements, function (element, index) {
      if (/^(FooterHtml|HeaderHtml|MeterHtml|hidden)$/.test(element.name) === false) {
        switch (element.name) {
          case 'PaymentInformation':
            context.form_elements.push({ type: 'payment_information', index: index }); break;
          case 'ContributionInformation':
            context.form_elements.push({ type: 'contribution', index: index }); break;
          case 'TicketInformation':
            context.form_elements.push({ type: 'ticketing', index: index }); break;
          case 'ConfirmIdentityHtml':
            context.form_elements.push({ type: 'notme', index: index }); break;
          case 'Column2Placeholder': break;
          case 'FastAction':
            context.form_elements.unshift({ type: element.type, index: index }); break;
          default:
            context.form_elements.push({ type: element.type, index: index }); break;
        }
      }
    }, this);

    return invokeCallbacks('alterContext', { element: 'form', context: context }).context;
  },
  render: function () {
    timing.start('Render', this.model.id);
    // If view form has errors (doesn't exist or is deactivated)
    // show only that error message and do nothing else.
    if (this.options.form_definition.errorCode) {
      this.$el.html(this.options.form_definition.message);
      return this;
    }

    // Check for form Deactivation, if deactivated show that message and stop render.
    if (this.options.form_definition.status === 'Deactivated') {
      if (this.options.form_definition.metadata.deactivationRedirectUrl) {

        var url = this.options.form_definition.metadata.deactivationRedirectUrl;
        var qsObj = this.model.get('options').query_string_case_preserved;
        if (qsObj) {
          var qs = $.param(qsObj);
          if (qs && qs.length) {
            // if query string already exists, append the qs from the window to the end
            var queryIdx = url.indexOf('?');
            if (queryIdx > -1) {
              // protect against the case where the url has a trailing '?'
              if (queryIdx === url.length - 1) {
                url += qs;
              }
              else {
                url += '&' + qs;
              }
            } else {
              url += '?' + qs;
            }
          }
        }
        window.location = url;
      } else if (this.options.form_definition.metadata.deactivationHtmlContent) {
        this.$el.html('<div class="ngp-deactivation-message">' +
          this.options.form_definition.metadata.deactivationHtmlContent + '</div>');
        return this;
      }
    }


    var isSecondaryAsk = !!this.options.last_form_state;
    this.options.last_form_state = isSecondaryAsk && _.isEmpty(this.val()) ? this.options.last_form_state : this.val();
    this.$el.html(this.template(this.context()));
    this.handleResizingIframe();
    this.addDataToForm();

    // after the html has been rendered, we can init recaptcha, since it needs to hook into a container element
    var self = this;
    recaptcha.initForm(this).then(
      function onRecaptchaInit() {
        // noop, just keep going
      },
      function onRecaptchaError(err) {
        var errMsg = isSecondaryAsk ?
          self.options.form_definition.resources.PrimaryResources.RecaptchaProblemLoadingSecondaryForm :
          self.options.form_definition.resources.PrimaryResources.RecaptchaProblemLoadingForm;
        self.$el.html(errMsg);
        var error = err || new Error('Recaptcha Load Error');
        var metaData = {
          ErrorType: 'Recaptcha Exception',
          FormSessionId: this.formSessionId,
          SecondaryAsk: isSecondaryAsk
        };
        telemetry.trackException({ exception: error, properties: metaData });
      });
    return this;
  },
  handleResizingIframe: function () {
    var self = this;
    window.addEventListener('resize', function () {
      iFrameResize();
    });

    window.addEventListener('load', function () {
      // adding class needed for stat board media queries
      if (self.multistep) {
        self.iFrameAddMultistepToBody('multistep-layout');
      }
      iFrameResize();

    });

    function iFrameResize() {
      var iframeList = document.querySelectorAll('iframe.meter-frame');
      if (iframeList && iframeList.length !== 0) {
        _.each(iframeList, function (iframe) {
          // Until we resolve VAN-65733, prevent errors from a resize strategy that doesn't work when embedded.
          var iframeLocation = new URL(iframe.src);
          if (iframeLocation.origin === location.origin) {
            iframe.style.height = iframe.contentDocument.body.scrollHeight + 'px';
          }
        });
      }
    }
  },
  errors: function () {
    return _(this.subviews).chain().invoke('errors').flatten().compact().value();
  },
  query_string_fill_dict: function () {
    // Take querystring dictionary and form definition and and return fill dictionary
    // qs: {"fn": "Victor", "ln": "Quinn", ...}
    // qs_field_dict: {"fn": "FirstName"}
    // return {FirstName: Victor, LastName: Quinn, ...}
    //
    // The above comment is super helpful. Thank you to whomever wrote it.

    var qs_field_dict = _.transform(
      FormDefUtils.querystring_dict(this.form_definition().form_elements, 'queryString'),
      // The qs dict keys are all made lowercase so as to ensure case-insensitive behavior.
      // Since we're finding the union of the two key sets, we need to lower-case this as well.
      function (result, value, key) { result[key.toLowerCase()] = value; }
    );

    return _.transform(this.options.query_string, function (result, value, key) {
      // Ignore value if key isn't in qs_field_dict
      if (qs_field_dict.hasOwnProperty(key)) {
        var fieldName = qs_field_dict[key];

        if (fieldName === 'EventIsPrivate') {
          // Since the UI flips the usage of the values, we need to fix the input here
          // in the formdef, 'False' = YES, show on website
          // in the formdef, 'True' = NO, do not show on website
          if (value !== null && value !== undefined) {
            var checkVal = value.toString().toLowerCase();
            if (checkVal === 'true' || checkVal === '1') {
              value = 'False'; // YES, show on website
            } else if (checkVal === 'false' || checkVal === '0') {
              value = 'True'; // NO, do not show on website
            }
          }
          result[fieldName] = value;
        } else if (_.isString(value) && /^(true|false)$/i.test(value)) {
          // Check for "true" | "false" in querystring for Checkboxes.
          result[fieldName] = /^true$/i.test(value);
        } else {
          result[fieldName] = value;
        }
      }
    });
  },
  /**
   * Fills the form.
   */
  fill: function () {
    var fill_dict = {}, fill_source = 'None', valueFillSources = {}, fill_sources = {
      qs: this.options.query_string,
      fast_action_user: FastActionUser,
      last_form_state: this.options.last_form_state
    };

    try {
      this.options.smart = this.isFirstForm() ? false : this.form_definition().metadata.hideCompletedFormSections;
    } catch (e) { console.log(e); }

    if (!this.options.fillDisabled) {
      fill_dict = this.get_fill_dict(fill_sources);
      fill_source = fill_dict._source;
      valueFillSources = fill_dict._value_sources;
      delete fill_dict._source;
      delete fill_dict._value_sources;
      this.render_fill_dict(fill_dict);
    }

    // Perform DoubleBracket filling
    var lookups = {};
    var genders = FormDefUtils.find_field(this.options.form_definition.form_elements, 'Genders');
    var race = FormDefUtils.find_field(this.options.form_definition.form_elements, 'Race');
    var pronoun = FormDefUtils.find_field(this.options.form_definition.form_elements, 'Pronoun');
    if (genders) {
      lookups.genders = genders.options;
    }
    if (race) {
      lookups.races = race.options;
    }
    if (pronoun) {
      lookups.pronouns = pronoun.options;
    }

    // This is where the input filling happens
    this.setval(fill_dict);

    DoubleBracket.fill(this, fill_dict, lookups);
    this.options.filled = true;

    this.quick(fill_sources);
    this.autoSubmit();
    if (_.size(this.subviews)) {
      this.renderStep(this.step('index'));
      _.invoke(this.subviewsWithName('TicketInformation'), 'changeQuantityOrAdditional');
    }

    if (!_.isEmpty(fill_dict)) {
      this.trigger('fill', fill_dict);
    }

    // keep track of how many times we have filled so we can determine when values get overwritten
    this.fillCounter++;

    if (telemetry.isEnabled()) {
      try {
        var loggableDict = _.mapValues(fill_dict, function (val, key) {
          if (_.contains(databag_config.allowlist, key)) {
            return val;
          }
          // redact value if its not a allowlisted key
          return (val || '').toString().replace(/./g, '*');
        });
        _.extend(loggableDict, {
          FillCounter: this.fillCounter,
          FormSessionId: this.formSessionId
        });
        _.forOwn(valueFillSources, function (val, key) {
          loggableDict[key + '_Source'] = val;
        });
        telemetry.trackEvent({ name: 'ActionTag Post-Fill', properties: loggableDict });
      } catch (err) {
        //womp
      }
    }
    return invokeCallbacks('postFill', {
      view: this,
      fill_dict: fill_dict,
      fill_source: fill_source,
      valueFillSources: valueFillSources,
      submit: _.bind(this.options.parent_tag.postBack, this, null, null, true, 'postFillArg')
    }).view;
  },
  /**
   * Instantly submits the form, if possible / autoSubmit conditions are met.
   */
  autoSubmit: function () {
    var qs = this.options.query_string;
    if (this.options.form_definition.autoSubmit &&
      !this.options.quick_complete &&
      !this.options.autoSubmitComplete &&
      !this.options.parent_tag.disableAutoSubmit &&
      this.errors().length === 0 &&
      !(qs.hasOwnProperty('autosubmitsuppressed') &&
        (qs.autosubmitsuppressed === 'true'))
    ) {
      this.options.autoSubmitComplete = true;
      this.options.parent_tag.postBack(null, {}, true, 'autoSubmit');
    }
    return this;
  },
  /**
   * Determines the fill source and builds a dictionary of fill values.
   *
   * @param Object dict
   *   qs -> query string.
   *   fast_action_user -> The FastActionUser model, which may be empty if e.g. FastAction is not enabled on the form
   *   last_form_state -> The user's last submitted form.
   *
   * @return Object
   *   The dictionary of fill values.
   */
  get_fill_dict: function (dict) {
    var view = this,
      fill_dict = this.val(),
      fill_source = 'None';

    if (fill_dict.Amount && fill_dict.Amount !== '0.00') {
      fill_dict.SelectAmount = fill_dict.Amount;
      fill_dict.SuggestedAmount = fill_dict.Amount;
    }

    // Anything in the initial dictionary is from form def defaults
    var valueFillSources = _.mapValues(fill_dict, function () {
      return 'defaults';
    });

    function fill(dict, source) {
      if (_.isEmpty(dict)) { return; }
      fill_source = source || fill_source;

      var fillData = _.omit(dict, function (val) { return val === '' });
      var fillDataSources = _.mapValues(fillData, function () {
        return source || 'special';
      });
      _.extend(valueFillSources, fillDataSources);
      return _.extend(fill_dict, fillData);
    }

    // Hierarchy
    //  1) Special Query String ('black_magic' values).
    //  2) Querystring
    //  3) FastAction User
    //  4) Previous Form State
    //  5) Nothing To Fill
    //
    // We probably want to replace this hierarchy with some more intelligent merging
    // if/when Product realizes that's the right approach ;)


    // These are special cases which are in the form definition,
    // but do not trigger "QueryString" fill and ignore other sources.
    var qs_fill_dict = this.query_string_fill_dict();
    var black_magic = ['SelectAmount', 'MarketSource'];
    var special_qs = _.pick(qs_fill_dict, black_magic);
    qs_fill_dict = _.omit(qs_fill_dict, black_magic);

    // 1) Special Query String.
    fill(special_qs);

    var isKioskEnabled = this.options.query_string && this.options.query_string.kiosk;
    if (view.isFirstForm()) {
      // 2) Querystring
      fill(qs_fill_dict, 'QueryString');
    }

    if (!isKioskEnabled && this.options.form_definition.fastAction) {
      // 3) FastAction
      fill(dict.fast_action_user.getProfileData(), 'FastAction');
    }

    // 4) Previous Form State
    fill(dict.last_form_state, 'PreviousFormState');
    if (fill_source !== 'None') {
      // 5) Nothing To Fill
      nvtag.track(this.model.get('type'), 'Form Fill', fill_source,
        _.size(_.omit(fill_dict, 'indexed_on', 'modified_on')), this);
    }

    // 6) fix aliased fill values
    aliasHelper.fixAliasFillValues(this.aliases, fill_dict);

    // reconcile Amount and SelectAmount so previous form state values propagate the fill_dict
    if (fill_dict.Amount && fill_dict.Amount !== '0.00' && dict.last_form_state.Amount) {
      fill_dict.SelectAmount = dict.last_form_state.Amount;
      fill_dict.SuggestedAmount = dict.last_form_state.Amount;
    }

    // if the employerOccupationRetiredCheckbox is present and checked, do not fill the Occupation or Employer text fields
    // based on FastAction login or querystrings
    var retiredCheckboxParentFieldsetView = this.multistep ? this.subviews.ContactInformation : this.subviews.EmployerInformation;
    var isRetiredCheckboxPresent = retiredCheckboxParentFieldsetView && retiredCheckboxParentFieldsetView.subviews.EmployerOccupationIsRetiredCheckbox;
    var isRetiredCheckboxChecked = isRetiredCheckboxPresent && retiredCheckboxParentFieldsetView.subviews.EmployerOccupationIsRetiredCheckbox.el.firstElementChild.checked;
    if (isRetiredCheckboxChecked) {
      fill_dict = _.omit(fill_dict, ['Occupation', 'Employer']);
    }

    fill_dict._source = fill_source;
    fill_dict._value_sources = valueFillSources;

    return invokeCallbacks('alterFill', {
      // Add (mix in, extend) default values from form definition
      fill_dict: _.extend({}, this.defaults, fill_dict),
      fill_source: fill_source
    })
      // The dictionary that will be used to do the filling. | The NVFormView of the form that's about to be filled.
      .fill_dict;
  },
  /**
   * Renders a dictionary of values.
   */
  render_fill_dict: function (fill_dict) {
    if (!this.isFirstForm()) {
      this.options.showNotMe = true;
    }

    // Add in any values that came in the POST-back
    if (!_.isEmpty(this.options.post_response)) {
      fill_dict = _.extend(fill_dict, this.options.post_response);
    }

    // We want to re-render the NotMe after filling
    _.invoke(this.subviewsWithName('ConfirmIdentityHtml'), 'render');
    return this;
  },
  /**
   * Instantly submits the form, if possible / quick conditions are met.
   *
   * @param Object dict
   *   qs -> query string.
   *   fast_action_user -> The FastActionUser model, which may be empty if e.g. FastAction is not enabled on the form
   *   last_form_state -> The user's last submitted form.
   */
  quick: function (dict) {
    var isTrue = /^true$/i;
    var excludedFormTypes = ['ContributionForm', 'EventForm'];
    if (
      excludedFormTypes.indexOf(this.options.form_definition.type) < 0 &&
      !this.options.quick_complete &&
      !this.options.autoSubmitComplete &&
      !this.options.parent_tag.disableAutoSubmit &&
      this.isFirstForm() &&
      dict.qs && dict.qs.quick &&
      isTrue.test(dict.qs.quick) &&
      this.errors().length === 0
    ) {
      this.options.quick_complete = true;
      this.options.parent_tag.postBack(null, {}, true, 'quickSubmit');
    }
    return this;
  },
  getTouched: function () {
    return _.transform(this.subviews, function (touched, subview, name) {
      if (_.isFunction(subview.getTouched)) {
        _.extend(touched, subview.getTouched());
      } else if (_.isFunction(subview.touched) && subview.touched()) {
        touched[name] = subview;
      }
    });
  },
  getVgsValues: function (submissionCorrelationProperties) {
    var pay = this.subviews.PaymentInformation;
    var isPayingWithCc = pay && (!pay.selectedPaymentMethod || pay.selectedPaymentMethod === 'creditcard');
    // In the situation where CC is not available as a payment method, the vgs form will not be present
    // which handles when selectedPaymentMethod may not be defined
    if (!this.vgs.form || !pay || pay.hide || !isPayingWithCc) {
      return $.Deferred().resolve({});
    }
    return vgs.submitForm(this.vgs.form, submissionCorrelationProperties).then(function (vgsValues) {
      if (vgsValues.ExpirationDate) {
        vgsValues.ExpirationMonth = vgsValues.ExpirationDate.ExpirationMonth;
        //use the last 2 digits of the input, in case it was entered as a 4-digit year
        vgsValues.ExpirationYear = vgsValues.ExpirationDate.ExpirationYear && vgsValues.ExpirationDate.ExpirationYear.substring(vgsValues.ExpirationDate.ExpirationYear.length - 2);
        delete vgsValues.ExpirationDate;
      }
      return vgsValues;
    });
  },
  getRecaptchaValues: function (submissionCorrelationProperties) {
    if (this.captcha.bypass) {
      // if the single-use bypass token is available, use that as the submission value
      return $.Deferred().resolve({
        CaptchaSecureToken: this.captcha.bypass
      });
    }
    return recaptcha.getResponse(this, submissionCorrelationProperties).then(function (result) {
      if (!result || !result.token) {
        return {};
      }

      return {
        CaptchaResponseToken: result.token,
        CaptchaTimeElaspedMs: result.elapsedMs
      };
    });
  },
  resetRecaptcha: function () {
    // remove the single-use bypass token (if exists)
    delete this.captcha.bypass;

    // ensure the recaptcha persisted token is reset
    return recaptcha.reset(this);
  },
  val: function (key) {
    var subview_val,
      val = key ? null : {};

    _.each(this.subviews, function (subview) {
      subview_val = subview.val();
      if (_.isObject(subview_val)) {
        if (key && subview_val.hasOwnProperty(key)) {
          val = subview_val[key];
          return false;
        } else {
          // If it's a dictionary, add it to this dictionary
          _.extend(val, subview_val);
        }
      } else if (subview_val !== null) {
        // If just a value, add it to the dictionary, but don't add nulls
        // Note, we used to exclude false-y values but that proved a problem
        // because sometimes we want to explicitly pass a "false" value onto
        // Oberon. This should fix it. By convention, .val() should return
        // null if its value should not be submitted back to Oberon.
        if (key && subview_val.hasOwnProperty(key)) {
          val = subview_val[key];
          return false;
        } else {
          val[subview.name] = subview_val;
        }
      }
    });
    return val;
  },

  /**
   * Clears all values set in this form
   */
  clear: function (reset) {
    var defaults = this.defaults;
    return this.setval(_.mapValues(this.val(), function (val, key) {
      if (key === 'Amount' && !reset) {
        return val;
      } else if (defaults.hasOwnProperty(key) && !reset) {
        return defaults[key];
      } else {
        return null;
      }
    }));
  },
  setval: function (values) { // TODO: This is identical to the method on fieldset. Fix! - Bjorn
    _.each(this.subviews, function (subview) {
      if (!_.isObject(subview.val())) {
        _.each(values, function (value, key) {
          if (subview.field_name() === key) {
            subview.setval(value);
          }
        });
      } else if (_.isFunction(subview.setval)) {
        subview.setval(values);
      }
    });
    return this;
  },
  // This handles autofill from paypal, apple pay, etc
  // Has some special handling to treat address fields as a unit
  externalAutoFill: (function () {
    var addressFields = { 'AddressLine1': '', 'AddressLine2': '', 'City': '', 'StateProvince': '', 'PostalCode': '' };
    var phoneFields = { 'HomePhone': '', 'MobilePhone': '' };

    return function externalAutoFill(dict) {
      // Filter out any empty values (but don't treat 0 as empty)
      dict = _.pick(dict, function (value) { return value || _.isNumber(value); });

      // We want to make sure that if the fill values include an address,
      // we don't leave bits of the old address around.  So check if the dictionary has such fields,
      // and default any missing values to empty strings
      var hasAddress = _(addressFields).keys()
        .any(function (key) { return key in dict; });
      if (hasAddress) {
        dict = _.defaults(dict, addressFields);
      }

      // Default any missing phone fields to empty strings
      dict = _.defaults(dict, phoneFields);

      aliasHelper.fixAliasFillValues(this.aliases, dict);

      this.setval(dict);
    };
  })(),
  setPageInfoAlert: function (alerts) {
    var alertView = this.subviews.page_alert;
    if (alertView) {
      alertView.setAlerts(alerts);
    }
  },
  focusOnNextRequiredValueOrSubmit: function (options) {
    options = options || {};
    var missingFields = [];
    var steps = this.multistep ? this.steps.length : 1;
    var currentStep = this.step('index') || 0;

    for (var i = currentStep; i < steps; i++) {
      // find the fields that are visible and need responses (some errors can only be found if they're visible)
      missingFields = _.filter(this.errors(), errorFieldFocusableIfElement);

      if (missingFields && missingFields.length) {
        // If there are missing fields, focus on the next one
        var focusableError = _.findWhere(missingFields, errorFieldIsFocusable);

        if (focusableError) {
          focus(focusableError.field.el);
        }

        if (options.shouldRenderFeedback) {
          invokeCallbacks('formErrors', { errors: missingFields });
          return this.renderFeedback();
        }

        return;
      } else if (i < steps - 1) {
        // if this is a multistep form and there are more steps to check, go to the next step
        this.nextStep();
      }
    }

    // if there are no missing fields, submit the form
    this.parent.postBack(null, {}, true, options.submissionType);
  },
  getBaseId: function () {
    return this.el.id;
  },
  createFormValuesEvent: function () {
    // VAN-105385: replaces logic found in gtmtools.js
    var otherAmount = document.querySelector('input[name=OtherAmount]');
    var amountOptions = document.querySelectorAll('input[name=SelectAmount]');
    var defaultam;
    var defaultAmountOptions = [];
    // Amount options can be disabled on a form
    if (amountOptions) {
      for (var i = 0; i < (amountOptions.length - 1); i++) {
        var options = amountOptions[i];
        defaultAmountOptions.push(options.getAttribute('value'));
      }
      if (otherAmount && otherAmount.value !== '') {
        defaultAmountOptions.push(otherAmount.value);
        defaultam = otherAmount.value;
      } else {
        var selectAmountElement = document.querySelector('input[name=SelectAmount]:checked');
        defaultam = selectAmountElement ? selectAmountElement.value : null;
      }
      window.dataLayer.push({
        event: 'FormValues',
        defaultValueOptions_array: defaultAmountOptions,
        defaultValueOptions_string: defaultAmountOptions.toString(),
        defaultValue: defaultam
      });
    }
  }
});
