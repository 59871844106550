import 'lib/lodash.custom';
import $ from 'lib/jquery';
import loadScript from 'app/loadScript';
import assetUrl from 'app/assetUrl';

function init() {
  if (!window.SignaturePad) {
    var signaturePadUrl = assetUrl('js/signature_pad.min.js');
    return loadScript(signaturePadUrl);
  }
  return $.Deferred().resolve().promise();
}

function setupSignaturePad(canvas, clearBtn, undoBtn) {

  var body = document.getElementsByTagName('body')[0];
  var prevWidth = body.offsetWidth;

  return init().then(function () {
    var signaturePad = new window.SignaturePad(canvas);

    window.addEventListener('resize',
      resizeCanvas.bind(null, signaturePad, canvas, false));

    window.addEventListener('signature-pad-multistep-rebind',
      resizeCanvas.bind(null, signaturePad, canvas, true));

    clearBtn.addEventListener('click',
      function () { signaturePad.clear(); });

    undoBtn.addEventListener('click',
      function () {
        var data = signaturePad.toData();
        if (data) {
          data.pop(); // remove the last dot or line
          signaturePad.fromData(data);
        }
      });

    // get correct initial scaling
    resizeCanvas(signaturePad, canvas, true);

    return signaturePad;
  });

  // Adjust canvas coordinate space taking into account pixel ratio,
  // to make it look crisp on mobile devices.
  // This also causes canvas to be cleared when browser is resized
  function resizeCanvas(signaturePad, canvas, setupInitialScale) {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio = Math.max(window.devicePixelRatio || 1, 1);

    // calculate the new window width
    var body = document.getElementsByTagName('body')[0];
    var newWidth = body.offsetWidth;

    // The size of the canvas doesn't change on vertical resizing
    // so only update the size & value when we detect a
    // horizontal change
    if (setupInitialScale === true || prevWidth !== newWidth) {
      prevWidth = newWidth;

      // Only if the actual size of the canvas is changing horizontally
      // should we clear & update the scaling
      var prevCanvasWidth = canvas.width;
      var newCanvasWidth = canvas.offsetWidth * ratio;

      if (prevCanvasWidth !== newCanvasWidth) {
        // This part causes the canvas to be cleared
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext('2d').scale(ratio, ratio);

        // This library does not listen for canvas changes, so after the canvas is automatically
        // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
        // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
        // that the state of this library is consistent with visual state of the canvas, you
        // have to clear it manually.
        signaturePad.clear();
      }
    }
  }
}

export default {
  setupSignaturePad: setupSignaturePad
};
