import Backbone from 'lib/backbone';
import FieldView from 'app/views/FieldView';
import invokeCallbacks from 'app/NVTagCallbacks';
import ApplePay from 'app/applePay';
import eaStripe from 'app/eaStripe';
import currency from 'app/views/helpers/currency';
import config from 'config/actionTagConfig';
import eaPayPal from 'app/eaPayPal';
import _ from 'lib/lodash.custom';

var $ = Backbone.$;
export default FieldView.extend({
  __name__: 'PaymentMethodView',
  tagName: 'div',
  className: 'at-payment-method-buttons',
  events: {
    'click label.at-btn-radio': 'selectPaymentMethodButton',
    'keypress label.at-btn-radio': 'selectPaymentMethodButton'
  },
  initialize: function () {
    FieldView.prototype.initialize.call(this);

    _.bindAll(this, 'recurrenceChanged');

    this.paypalWaitCounter = 0;
    this.paypalInstance = null;
    this.PayPalCommercePlatformInstance = this.options.formview.PayPalCommercePlatformInstance;
    this.paymentMethodConfiguration = this.options.formview.paymentMethodConfiguration;
    this.contributionAmountModel = this.options.formview.contributionAmountModel;
    this.contributionRecurrenceModel = this.options.formview.contributionRecurrenceModel;

    this.listenTo(this.options.formview, 'formResized renderStep', this.setWrapStyling);
    this.listenTo(this.options.formview, 'preAutoProcess', this.preAutoProcess);

    this.contributionRecurrenceModel.on('change', this.recurrenceChanged);
  },
  context: function () {
    var config = this.paymentMethodConfiguration;
    var context = {
      ccEnabled: config.isCcEnabled,
      paypalEnabled: config.isPayPalEnabled,
      venmoEnabled: config.isVenmoEnabled,
      eftEnabled: config.isEftEnabled,
      paragonApplePayEnabled: config.isParagonApplePayEnabled,
      stripeApplePayEnabled: config.isStripeApplePayEnabled,
      googlePayEnabled: config.isGooglePayEnabled,
      idSuffix: this.options.formview.options.form_definition.formId,
      alignRight: this.options.alignment === 'right',
      isBraintreeIntegration: config.payPalIntegrationType === 'Braintree',
      stripeJsEnabled: config.isStripeJsEnabled
    };

    return invokeCallbacks('alterContext', { element: this.type, context: context, def: this.def }).context;
  },
  render: function () {
    if (this.paymentMethodConfiguration.showPaymentMethodButtons) {
      var context = this.context();
      this.$el.html(this.template(context));
    }

    this.setWrapStyling();

    // Select a default payment method only if not shown first or method already selected
    if (!this.paymentMethodConfiguration.shouldFillFromPaymentResponse || this.selectedPaymentMethod) {
      var availableMethods = this.getAvailablePaymentMethods();

      // If the selected payment method is not available, set the payment method the first available method
      var method = availableMethods.indexOf(this.selectedPaymentMethod) !== -1
        ? this.selectedPaymentMethod
        : availableMethods[0];

      this.setPaymentMethod(method, false);
    }

    if (this.paymentMethodConfiguration.isStripeJsEnabled) {
      this.initializeStripePaymentRequest();

      var enabledExpressPaymentMethods = [];
      if (this.paymentMethodConfiguration.isGooglePayEnabled) {
        enabledExpressPaymentMethods.push('googlePay');
      }
      if (enabledExpressPaymentMethods.length > 0) {
        // Create the Stripe Express Checkout button
        this.createStripeExpressCheckout(enabledExpressPaymentMethods);
      }
    }

    if (this.paymentMethodConfiguration.isVenmoEnabled && this.paymentMethodConfiguration.payPalIntegrationType === 'PayPalCommercePlatform') {
      this.createPayPalCommercePlatformButton('venmo');
    }
    if (this.paymentMethodConfiguration.isPayPalEnabled && this.paymentMethodConfiguration.payPalIntegrationType === 'PayPalCommercePlatform') {
      this.createPayPalCommercePlatformButton('paypal');
    } else if (this.paymentMethodConfiguration.isPayPalEnabled && this.paymentMethodConfiguration.payPalIntegrationType === 'Braintree') {
      this.initPayPalBraintreeInstance();
    }
    return this;
  },
  setWrapStyling: function () {
    if (this.paymentMethodConfiguration.showPaymentMethodButtons) {
      this.$('.at-radio-set-buttons').removeClass('at-radio-set-button-wrap-styling');

      var shouldAddWrapStyling = false;
      if (this.options.alignment === 'right') {
        // If the position is less than 0, that means it is further to the left than the parent element,
        // so we'd want to wrap. The 5 is a rough estimate of the padding, which isn't accounted for.
        shouldAddWrapStyling = this.$('.at-btn-radio:first').position().left < 5;
      } else {
        // If we're left aligned, compare the right point of the last radio button to the right point of the parent element.
        var lastButtonPositionRight = this.$('.at-btn-radio:last').position().left + this.$('.at-btn-radio:last').innerWidth();
        var parentSectionPositionRight = this.$el.position().left + this.$el.innerWidth();
        shouldAddWrapStyling = parentSectionPositionRight < lastButtonPositionRight;
      }

      if (shouldAddWrapStyling) {
        // This adds new ordering, button width, and wrapping
        this.$('.at-radio-set-buttons').addClass('at-radio-set-button-wrap-styling');
      }
    }
  },
  recurrenceChanged: function () {
    // Capture recurrence changes to update the PayPal/Venmo buttons
    if (this.paymentMethodConfiguration.isPayPalEnabled && this.paymentMethodConfiguration.payPalIntegrationType === 'PayPalCommercePlatform') {
      var buttonMode = this.contributionRecurrenceModel.get('frequency') === 5 ? 'token' : 'order';
      this.PayPalCommercePlatformInstance.createOrUpdatePayPalButton(buttonMode);

      if (this.paymentMethodConfiguration.isVenmoEnabled && !!this.PayPalCommercePlatformInstance.venmoButton?.isEligible()) {
        this.$('.at-venmo-button-wrapper').toggle(buttonMode !== 'token');
      }
      this.setWrapStyling();
    }
  },
  getAvailablePaymentMethods: function () {
    var isRecurring = this.contributionRecurrenceModel.get('isRecurring');
    // Filter out apple pay if recurring is enabled
    return _.filter(this.paymentMethodConfiguration.acceptedPaymentMethods, function (method) {
      return method !== 'applepay' || !isRecurring;
    });
  },
  preAutoProcess: function () {
    if (!this.selectedPaymentMethod) {
      var method = this.getAvailablePaymentMethods()[0];
      this.setPaymentMethod(method, true);
    }
  },
  val: function () {
    return this.selectedPaymentMethod || null;
  },
  errors: function () {
    if (!this.selectedPaymentMethod) {
      return [this.toError(this.resources.PrimaryResources.SelectPaymentMethod)];
    }

    return [];
  },
  selectPaymentMethodButton: function (e) {
    // Allow click or space or enter bars to select payment method
    if (e.type === 'click' ||
      (e.type === 'keypress' &&
        (e.keyCode === 13 || e.keyCode === 32))) {
      e.preventDefault();
      this.clearFeedback();

      // Find the input via the for attribute on the button/label
      var method = this.$('#' + e.currentTarget.htmlFor).val();

      if (method === 'paypal' && this.paymentMethodConfiguration.payPalIntegrationType === 'Braintree') {
        this.authorizePayPalBraintree();
      } else if (method === 'applepay' && this.paymentMethodConfiguration.isStripeApplePayEnabled && this.paymentMethodConfiguration.isStripeJsEnabled) {
        this.authorizeStripePaymentRequest();
      } else if (method === 'applepay') {
        this.authorizeApplePay();
      } else {
        this.setPaymentMethod(method, true);
      }
    }
  },
  setPaymentMethod: function (newMethod, triggerSelection) {
    // Set the payment method input to the new value and broadcast the change from the formview
    // triggerSelection indicates whether the event was triggered by user selection or not
    this.selectedPaymentMethod = newMethod;
    this.$('input[name="PaymentMethod"]')
      .prop('checked', false);
    this.$('input[name="PaymentMethod"][value=' + newMethod + ']')
      .prop('checked', true);

    this.options.formview.trigger('paymentMethodChanged', {
      newMethod: this.selectedPaymentMethod,
      isUserSelection: triggerSelection
    });
  },
  toggleOverlay: function (display) {
    if (display) {
      if ($('.at-paypal-overlay').length === 0) {
        $(document.documentElement).addClass('has-overlay');
        $('body').append($('<div/>').addClass('at-paypal-overlay'));
      }
    } else {
      $(document.documentElement).removeClass('has-overlay');
      $('.at-paypal-overlay').remove();
    }
  },
  initPayPalBraintreeInstance: function () {
    var self = this;
    var formUrl = this.options.url.base + 'PayPalClientToken/' + this.options.url.full.split('/')[5];
    eaPayPal.initPayPalBraintreeInstance(formUrl).then(
      function (payPalInstance) {
        self.paypalInstance = payPalInstance;
      }
    ).catch(
      function (payPalInstanceFailed) {
        self.paypalInstanceFailed = payPalInstanceFailed;
      }
    );
  },
  createPayPalCommercePlatformButton: async function (paymentMethod) {
    var self = this;
    var formUrl = this.options.url.base + this.options.url.full.split('/')[5];
    var formName = this.options.formview.options.form_definition.name;
    var errorMessage = paymentMethod === 'venmo' ? self.resources.PrimaryResources.VenmoUnknownError : self.resources.PrimaryResources.PayPalUnknownError;

    var responseHandler = function (response, message) {
      if (response) {
        return response;
      } else {
        self.renderFeedbackWithErrors([self.toError(errorMessage)]);
        self.options.formview.trigger('paypalAuthorized');
        return $.Deferred().reject(message);
      }
    };

    // Configure the createOrder/onApproveOrder functions here since these require/result in PaymentMethodView state changes
    // Create config for Order flow
    var orderConfig = {
      createOrder: async function (data) {
        var response = await self.PayPalCommercePlatformInstance.createOrder(
          formUrl,
          self.contributionAmountModel.get('totalAmount'),
          self.contributionRecurrenceModel.get('isRecurring'),
          formName,
          paymentMethod,
          data);

          return responseHandler(response, 'PayPalCommercePlatform error on create order');
      },
      onApprove: async function (data) {
        var paypalOrder = await self.PayPalCommercePlatformInstance.onApproveOrder(formUrl, paymentMethod, data);
        if (paypalOrder) {
          self.clearFeedback();
          self.options.formview.trigger('paypalAuthorized', {
            paypalNonce: paypalOrder.Id,
            payPalNonceType: 'Order',
            fundingSource: paymentMethod,
            amount: paypalOrder.PurchaseUnits[0]?.Amount?.Value,
            email: paypalOrder.Payer?.EmailAddress,
            mappedContactFields: self.PayPalCommercePlatformInstance.mapOrderResponse(paypalOrder),
            expires: Date.now() + 60000 * config.PAYPAL_COMMERCE_PLATFORM_SESSION_TIMEOUT_MINUTES
          });

          self.setPaymentMethod(paymentMethod, true);
          return paypalOrder;
        }

        return responseHandler(paypalOrder, 'PayPalCommercePlatform error on approve order');
      }
    };

    // Create config for Token flow (not supported for Venmo)
    var tokenConfig = paymentMethod === 'venmo' ? null : {
      createVaultSetupToken: async function (data) {
        var response = await self.PayPalCommercePlatformInstance.createToken(formUrl, formName, paymentMethod, data);
        return responseHandler(response, 'PayPalCommercePlatform error on create token');
      },
      onApprove: async function (data) {
        var paypalToken = await self.PayPalCommercePlatformInstance.onApproveToken(formUrl, paymentMethod, data);
        if (paypalToken) {
          self.clearFeedback();
          self.options.formview.trigger('paypalAuthorized', {
            paypalNonce: paypalToken.Id,
            payPalNonceType: 'Token',
            amount: self.contributionAmountModel.get('totalAmount'),
            email: paypalToken?.PaymentSource?.PayPal?.EmailAddress,
            mappedContactFields: self.PayPalCommercePlatformInstance.mapTokenResponse(paypalToken),
            expires: Date.now() + 60000 * config.PAYPAL_COMMERCE_PLATFORM_SESSION_TIMEOUT_MINUTES
          });

          self.setPaymentMethod(paymentMethod, true);
          return paypalToken;
        }
        return responseHandler(paypalToken, 'PayPalCommercePlatform error on approve token');
      }
    };

    var buttonMode = this.contributionRecurrenceModel.get('frequency') === 5 ? 'token' : 'order';
    var isButtonEligible = await this.PayPalCommercePlatformInstance.createButton(paymentMethod, orderConfig, tokenConfig, buttonMode);
    this.$(`.at-${paymentMethod}-button-wrapper`).toggle(isButtonEligible);
    this.setWrapStyling();
  },
  onUpsellAccepted: function () {
    var totalAmount = this.contributionAmountModel.get('proposedTotalAmount');
    var oneTimeAmount = this.contributionAmountModel.get('proposedOneTimeAmount');
    var recurringAmount = this.contributionAmountModel.get('proposedRecurringAmount');
    // A couple of payment methods require special handling
    switch (this.selectedPaymentMethod) {
      case 'paypal':
        return this.reauthorizePayPalForUpsell(totalAmount);
      case 'applepay':
          if (this.paymentMethodConfiguration.isStripeApplePayEnabled && this.paymentMethodConfiguration.isStripeJsEnabled) {
            return this.reauthorizeStripePaymentRequestforUpsell(recurringAmount, oneTimeAmount, totalAmount);
          }
          return this.reauthorizeApplePayforUpsell(recurringAmount, oneTimeAmount, totalAmount);
      default:
        return $.Deferred().resolve();
    }
  },
  reauthorizePayPalForUpsell: function (totalAmount) {
    if (!this.paymentMethodConfiguration.isPayPalEnabled) {
      return;
    }

    var self = this;
    // Add overlay to form until paypal authorization is complete
    // Since the supporter has previously authed with paypal on this page,
    // we don't need to wait for the paypal instance to initialize
    this.toggleOverlay(true);

    var displayName = this.options.designation || this.resources.PrimaryResources.TheForm;
    var paypalSettings = {
      // Must authorize with vault to be able to charge recurring or split contributions
      flow: 'vault',
      // Required for Checkout flow
      amount: totalAmount,
      // Required for Checkout flow
      currency: currency.getCurrency().code,
      // If fill is enabled, request the shipping address to get more contact information
      enableShippingAddress: false,
      // Used for some UI features
      displayName: displayName,
      // Use "Pay Now" language instead of "Continue"
      useraction: 'commit'
    };

    // Open the PayPal Checkout modal
    // Uses the v3 braintree api
    // https://braintree.github.io/braintree-web/3.45.0/PayPal.html
    return this.paypalInstance.tokenize(paypalSettings)
      .then(function (payload) {
        // Set the PayPal details in the Payment Information section
        self.options.formview.trigger('paypalAuthorized', {
          paypalNonce: payload.nonce,
          amount: totalAmount,
          email: payload.details.email,
          expires: Date.now() + 60000 * config.PAYPAL_SESSION_TIMEOUT_MINUTES
        });

        self.toggleOverlay(false);
      }).catch(function (error) {
        try {
          // On an error during reauth, we deliberately don't broadcast the failure -- the form should be left in a good state
          // in case the donor then declines.

          if (error.code === 'PAYPAL_TOKENIZATION_REQUEST_ACTIVE') {
            // If a window exists, focus on it
            self.paypalInstance.focusWindow();
          } else if (error.code !== 'PAYPAL_POPUP_CLOSED' && error.code !== 'PAYPAL_POPUP_OPEN_FAILED') {
            // If PayPal authorization failed, show the following message
            self.renderFeedbackWithErrors
              ([
                self.toError(self.resources.PrimaryResources.PayPalAuthorizationError)
              ]);
          }

          if (error.code === 'PAYPAL_POPUP_CLOSED') {
            return { IsCancelled: true };
          }
          else {
            return { IsError: true };
          }
        } finally {
          self.toggleOverlay(false);
        }
      });
  },
  authorizePayPalBraintree: function () {
    if (!this.paymentMethodConfiguration.isPayPalEnabled) {
      return;
    }

    var self = this;

    // Add overlay to form until paypal authorization is complete
    this.toggleOverlay(true);

    // Because the call to braintree to fetch the token is slow, the user can initiate the PayPal workflow
    // before the token is returned.  Handle that case here by retrying every 100ms for up to 5 seconds.
    if (!this.paypalInstance) {
      if (this.paypalWaitCounter < 50 && !this.paypalInstanceFailed) {
        this.paypalWaitCounter += 1;

        setTimeout(function () {
          self.authorizePayPalBraintree();
        }, 100);
      } else {
        this.toggleOverlay(false);
        this.renderFeedbackWithErrors([
          this.toError(this.resources.PrimaryResources.PayPalAuthorizationError)
        ]);
      }

      return;
    }

    var amount = this.contributionAmountModel.get('totalAmount');
    var displayName = this.options.designation || this.resources.PrimaryResources.TheForm;
    var isRecurring = this.contributionRecurrenceModel.get('isRecurring');
    var paypalSettings = {
      // once PayPal is authorized with checkout, it can only be used once
      flow: isRecurring ? 'vault' : 'checkout',
      // Required for Checkout flow
      amount: amount,
      // Required for Checkout flow
      currency: currency.getCurrency().code,
      // If fill is enabled, request the shipping address to get more contact information
      enableShippingAddress: this.paymentMethodConfiguration.shouldFillFromPaymentResponse,
      // Used for some UI features
      displayName: displayName,
      // Use "Pay Now" language instead of "Continue"
      useraction: this.paymentMethodConfiguration.isInstantPaymentProcessingEnabled ? 'commit' : ''
    };

    // Open the PayPal Checkout modal
    // Uses the v3 braintree api
    // https://braintree.github.io/braintree-web/3.45.0/PayPal.html
    this.paypalInstance.tokenize(paypalSettings)
      .then(function (payload) {
        // Remove any previous validation
        self.clearFeedback();
        // Set the PayPal details in the Payment Information section
        self.options.formview.trigger('paypalAuthorized', {
          paypalNonce: payload.nonce,
          amount: amount,
          email: payload.details.email,
          mappedContactFields: eaPayPal.mapPayPalBraintreeResponse(payload.details),
          expires: Date.now() + 60000 * config.PAYPAL_SESSION_TIMEOUT_MINUTES
        });

        // Set payment method to PayPal, possibly triggering submission
        self.setPaymentMethod('paypal', true);

        self.toggleOverlay(false);
      }).catch(function (error) {
        try {
          self.options.formview.trigger('paypalAuthorized');

          if (error.code === 'PAYPAL_TOKENIZATION_REQUEST_ACTIVE') {
            // If a window exists, focus on it
            self.paypalInstance.focusWindow();
          } else if (error.code !== 'PAYPAL_POPUP_CLOSED' && error.code !== 'PAYPAL_POPUP_OPEN_FAILED') {
            // If PayPal authorization failed, show the following message
            self.renderFeedbackWithErrors
              ([
                self.toError(self.resources.PrimaryResources.PayPalAuthorizationError)
              ]);
          }
        } finally {
          self.toggleOverlay(false);
        }
      });
  },
  // This method is used when, because of an upsell, we need to reauth ApplePay after the user has already authed once
  // On success it broadcasts the set payment method, but on an error only returns that status
  // It does not trigger a form fill.
  reauthorizeApplePayforUpsell: function (recurringAmount, oneTimeAmount, totalAmount) {
    var self = this;
    var controllerUrl = this.options.url.base + 'Apple/' + this.options.url.full.split('/')[5] + '/GetSession';
    var displayName = this.options.designation || this.resources.PrimaryResources.Amount;

    // Display the recurring gift, as well as (potentially) the one time charge for splits
    var displayItems = [];

    if (oneTimeAmount) {
      displayItems.push({
        label: self.resources.PrimaryResources.OneTimeContribution,
        amount: { value: oneTimeAmount, currency: currency.getCurrency().code }
      });
    }

    if (recurringAmount) {
      displayItems.push({
        label: self.resources.PrimaryResources.RecurringContribution,
        amount: { value: recurringAmount, currency: currency.getCurrency().code }
      });
    }

    return ApplePay.promptApplePay(
      this.options.appleMerchantIdentifier,
      this.options.cards,
      totalAmount,
      controllerUrl,
      this.options.title,
      false, // no email asked for on reauth
      false, // no phone asked for on reauth
      displayName,
      displayItems
    ).then(function (results) {
      if (results.paymentResponseDetails) {

        // Set the Apple Pay details in the Payment Information section
        self.options.formview.trigger('applePayAuthorized', {
          paymentResponseDetails: results.paymentResponseDetails,
          amount: totalAmount,
          cardInfo: results.paymentResponseDetails.token.paymentMethod.displayName,
          expires: results.expires
        });
      } else {
        if (results.applePayInternalError) {
          // If Apple Pay session authorization failed, show the following message
          self.renderFeedbackWithErrors([
            self.toError(self.resources.PrimaryResources.ApplePayUnknownError)
          ]);
        }

        if (results.applePayInternalError) {
          return { IsError: true };
        } else {
          return { IsCancelled: true };
        }
      }
    });
  },
  authorizeApplePay: function () {
    var self = this;

    var amount = this.contributionAmountModel.get('totalAmount');
    var controllerUrl = this.options.url.base + 'Apple/' + this.options.url.full.split('/')[5] + '/GetSession';

    var displayName = this.options.designation || this.resources.PrimaryResources.Amount;

    // Display items will be shown in the payment sheet
    var displayItems = [];
    if (this.contributionRecurrenceModel.get('isRecurring')) {
      displayItems.push({
        label: this.contributionRecurrenceModel.getFrequencyDisplayName() + ' ' + self.resources.PrimaryResources.Contribution,
        amount: { value: amount, currency: currency.getCurrency().code }
      });
    }

    ApplePay.promptApplePay(
      this.options.appleMerchantIdentifier,
      this.options.cards,
      amount,
      controllerUrl,
      this.options.title,
      this.options.requireEmail,
      this.options.requirePhone,
      displayName,
      displayItems
    ).then(function (results) {
      if (results.paymentResponseDetails && results.fill_dict) {
        // Remove any previous validation
        self.clearFeedback();

        // Set the Apple Pay details in the Payment Information section
        self.options.formview.trigger('applePayAuthorized', {
          paymentResponseDetails: results.paymentResponseDetails,
          amount: amount,
          cardInfo: results.paymentResponseDetails.token.paymentMethod.displayName,
          mappedContactFields: results.fill_dict,
          expires: results.expires
        });

        // Set payment method to Apple Pay, possibly triggering submission
        self.setPaymentMethod('applepay', true);
      } else {
        if (results.applePayInternalError) {
          // If Apple Pay session authorization failed, show the following message
          self.renderFeedbackWithErrors([
            self.toError(self.resources.PrimaryResources.ApplePayUnknownError)
          ]);
        }

        self.options.formview.trigger('applePayAuthorized');
      }
    });
  },
  initializeStripePaymentRequest: async function () {
    var self = this;
    var amount = this.contributionAmountModel.get('totalAmount');
    var displayName = this.options.designation || this.resources.PrimaryResources.Amount;

    // Display items will be shown in the payment sheet
    var displayItems = [];
    if (this.contributionRecurrenceModel.get('isRecurring')) {
      displayItems.push({
        label: this.contributionRecurrenceModel.getFrequencyDisplayName() + ' ' + self.resources.PrimaryResources.Contribution,
        amount: Math.round(amount * 100)
      });
    }

    var canMakePaymentResult = await eaStripe.createStripePaymentRequest(
      this.options.formview.formSessionId,
      this.options.stripeAccountId,
      Math.round(amount * 100),
      displayName,
      this.options.requireEmail,
      this.options.requirePhone,
      displayItems
    );

    if (canMakePaymentResult && self.paymentMethodConfiguration.isStripeApplePayEnabled && canMakePaymentResult.applePayEnabled) {
      self.$('.at-stripe-apple-pay-button-container').show();
      self.setWrapStyling();
    }
  },
  authorizeStripePaymentRequest: async function () {
    var self = this;
    var amount = this.contributionAmountModel.get('totalAmount');
    var displayName = this.options.designation || this.resources.PrimaryResources.Amount;

    // Display items will be shown in the payment sheet
    var displayItems = [];
    if (this.contributionRecurrenceModel.get('isRecurring')) {
      displayItems.push({
        label: this.contributionRecurrenceModel.getFrequencyDisplayName() + ' ' + self.resources.PrimaryResources.Contribution,
        amount: Math.round(amount * 100)
      });
    }

    var results = await eaStripe.promptStripePaymentRequest(
      this.options.formview.formSessionId,
      this.options.stripeAccountId,
      Math.round(amount * 100),
      displayName,
      displayItems
    );

    if (results.paymentResponseDetails && results.fill_dict) {
      // Remove any previous validation
      self.clearFeedback();

      // Set the Apple Pay details in the Payment Information section
      self.options.formview.trigger('stripeApplePayAuthorized', {
        paymentResponseDetails: results.paymentResponseDetails,
        amount: amount,
        cardInfo: results.paymentResponseDetails.token?.card?.brand +' '+ results.paymentResponseDetails.token?.card?.last4,
        mappedContactFields: results.fill_dict,
        expires: results.expires
      });

      // Set payment method, possibly triggering submission
      self.setPaymentMethod('applepay', true);
    } else {
      if (!!results.stripePaymentInternalError) {
        self.renderFeedbackWithErrors([
          self.toError(self.resources.PrimaryResources.ApplePayUnknownError)
        ]);

        self.options.formview.trigger('stripeApplePayAuthorized');
      }
    }
  },
  // This method is used when, because of an upsell, we need to reauth Stripe Payment Request after the user has already authed once
  // On success it broadcasts the set payment method, but on an error only returns that status
  // It does not trigger a form fill.
  reauthorizeStripePaymentRequestforUpsell: async function (recurringAmount, oneTimeAmount, totalAmount) {
    var self = this;
    var displayName = this.options.designation || this.resources.PrimaryResources.Amount;

    // Display the recurring gift, as well as (potentially) the one time charge for splits
    var displayItems = [];

    if (oneTimeAmount) {
      displayItems.push({
        label: self.resources.PrimaryResources.OneTimeContribution,
        amount: Math.round(oneTimeAmount * 100)
      });
    }

    if (recurringAmount) {
      displayItems.push({
        label: self.resources.PrimaryResources.RecurringContribution,
        amount: Math.round(recurringAmount * 100)
      });
    }

    var result = await eaStripe.promptStripePaymentRequest(
      this.options.formview.formSessionId,
      this.options.stripeAccountId,
      Math.round(totalAmount * 100),
      displayName,
      displayItems
    );

    if (result.paymentResponseDetails) {
      // Set the Apple Pay details in the Payment Information section
      self.options.formview.trigger('stripeApplePayAuthorized', {
        paymentResponseDetails: result.paymentResponseDetails,
        amount: totalAmount,
        cardInfo: result.paymentResponseDetails.token?.card?.brand +' '+ result.paymentResponseDetails.token?.card?.last4,
        expires: result.expires
      });
    }
    else {
      if (result.stripePaymentInternalError) {
        self.renderFeedbackWithErrors([
          self.toError(self.resources.PrimaryResources.ApplePayUnknownError)
        ]);
        return { IsError: true };
      } else {
        return { IsCancelled: true };
      }
    }
  },
  createStripeExpressCheckout: async function (paymentMethods) {
    var self = this;
    var amount = this.contributionAmountModel.get('totalAmount');
    var displayName = this.options.designation || this.resources.PrimaryResources.Amount;

    // Display items will be shown in the payment sheet
    var displayItems = [];
    if (this.contributionRecurrenceModel.get('isRecurring')) {
      displayItems.push({
        name: this.contributionRecurrenceModel.getFrequencyDisplayName() + ' ' + self.resources.PrimaryResources.Contribution,
        amount: amount
      });
    }

    var stripeExpressCheckout = await eaStripe.createStripeExpressCheckout(
      this.options.formview.formSessionId,
      this.options.stripeAccountId,
      paymentMethods,
      amount,
      displayName,
      this.options.requireEmail,
      this.options.requirePhone,
      displayItems,
      this.contributionRecurrenceModel?.get('frequency')
    );

    if (!stripeExpressCheckout) {
      return;
    }

    // Handle Express Checkout ready event
    stripeExpressCheckout.on('ready', ({availablePaymentMethods}) => {
      if (!availablePaymentMethods) {
        self.$('.at-express-checkout-element-wrapper').hide();
      }
      else {
        self.$('.at-express-checkout-element-wrapper').show();
        // Trigger wrap styling to ensure buttons are displayed properly after the element is mounted
        self.setWrapStyling();
      }
    });

    // Handle Express Checkout confirm event
    stripeExpressCheckout.on('confirm', async function(event) {
      let paymentMethod, eventName, errorMessage;
      paymentMethod = event.expressPaymentType === 'google_pay' ? 'googlepay' : 'unknown';

      if (paymentMethod === 'googlepay') {
        eventName = 'googlePayAuthorized';
        errorMessage = self.resources.PrimaryResources.GooglePayUnknownError;
      } else {
        eventName = 'unknown';
      }

      var tokenResult = await eaStripe.createStripeConfirmationToken(self.options.formview.formSessionId, self.options.stripeAccountId);

      if(tokenResult?.confirmationToken){
        self.options.formview.trigger(eventName, {
          confirmationTokenId: tokenResult.confirmationToken.id,
          confirmationTokenCreationMode: tokenResult.confirmationTokenCreationMode,
          amount: tokenResult.amount,
          cardInfo: tokenResult.confirmationToken.payment_method_preview?.card?.brand + ' ' + tokenResult.confirmationToken.payment_method_preview?.card?.last4,
          mappedContactFields: tokenResult.fill_dict,
          expires: tokenResult.confirmationToken?.expires_at
        });

        // Set payment method, possibly triggering submission
        self.setPaymentMethod(paymentMethod, true);
      } else {
        self.renderFeedbackWithErrors([
          self.toError(errorMessage)
        ]);

        self.options.formview.trigger(eventName);
      }
    });
  },
  type: 'payment_method'
});
